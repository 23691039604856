import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import fetcher from '@/lib/fetcher';

import type { Token } from '@/lib/tokens';
import type { Expand } from '@/types';

type UseFilteredTokensOptions = {
  liquidityThreshold?: number;
};

export function useFilteredTokens({ liquidityThreshold }: UseFilteredTokensOptions = {}) {
  const { data: filteredTokenMap } = useSWRImmutable<{
    [address: string]: Expand<
      {
        priceUSD: number;
        liquidityUSD: number;
      } & Omit<Token, 'whitelisted'>
    >;
  }>(`${process.env.API_HOST}/tokens/filtered`, fetcher);

  const tokens = useMemo(() => {
    if (!filteredTokenMap) return [];

    let filteredTokens = Object.values(filteredTokenMap);

    if (liquidityThreshold) {
      filteredTokens = filteredTokens.filter(
        ({ liquidityUSD }) => liquidityUSD > liquidityThreshold,
      );
    }

    return filteredTokens.map<Token>(({ priceUSD, liquidityUSD, ...token }) => ({
      ...token,
      whitelisted: false,
    }));
  }, [filteredTokenMap, liquidityThreshold]);

  return {
    tokens,
    filteredTokenMap,
  };
}
