import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import TokenAmountInputV2 from '@/components/TokenAmountInputV2';
import { useWalletContext } from '@/context/wallet';
import { VALID_FLOAT_REGEX } from '@/defines/consts';
import { ZERO_ADDRESS } from '@/defines/token-address';
import useBalancesV1 from '@/hooks/use-balances-v1';
import useTokens from '@/hooks/use-tokens';
import { useWeb3Transaction } from '@/lib/useWeb3Transaction';
import bigIntMax from '@/utils/bigIntMax';
import numerateAmount from '@/utils/numerateAmount';

import type { ChatProfile } from '@/components/pro-chat/types';

export type ChatSendTokenDialogProps = {
  open: boolean;
  onClose: () => void;
  chatProfile: ChatProfile | null;
  nickname: string;
};

export const ChatSendTokenDialog: React.FC<ChatSendTokenDialogProps> = ({
  open,
  chatProfile,
  nickname,
  onClose,
}) => {
  const { t } = useTranslation('chat');
  const { t: tc } = useTranslation('common');

  const { tokenAddressToToken } = useTokens({ withImaginaryFiats: true });

  const { wallet } = useWalletContext();
  const { balances, mutate: mutateBalances } = useBalancesV1({
    skip: !open,
    account: wallet?.account ?? null,
  });

  const [selectedToken, setSelectedToken] = useState<{ address: string; decimals: string }>({
    address: ZERO_ADDRESS,
    decimals: tokenAddressToToken[ZERO_ADDRESS]?.decimals ?? '18',
  });

  const [transferAmount, setTransferAmount] = useState<string>('');

  useEffect(() => setTransferAmount(''), [selectedToken.address]);

  const numeratedAmount = useMemo(() => {
    if (!transferAmount || !selectedToken) return 0;
    return numerateAmount({ amount: transferAmount, decimals: selectedToken.decimals });
  }, [transferAmount, selectedToken]);

  const rawBalance = useMemo(() => {
    if (!balances || !selectedToken?.address) return 0;

    return balances[selectedToken.address] || 0;
  }, [balances, selectedToken?.address]);

  const balance = useMemo(() => {
    if (!tokenAddressToToken || !selectedToken || !tokenAddressToToken[selectedToken.address])
      return 0;

    return +rawBalance / 10 ** parseInt(tokenAddressToToken[selectedToken.address].decimals, 10);
  }, [rawBalance, tokenAddressToToken, selectedToken]);

  const availableMaxAmount = useMemo(() => {
    if (!rawBalance) {
      return undefined;
    }
    let maxBalance = BigInt(rawBalance);

    // Native token with decimals 18
    if (selectedToken?.address === ZERO_ADDRESS) {
      const safeAmount = maxBalance - BigInt(10 ** 18);
      maxBalance = bigIntMax(safeAmount, 0n);
    }

    return maxBalance;
  }, [selectedToken?.address, rawBalance]);

  const validateTransferAmount = useCallback(
    (value: string) => {
      if (!value || !parseFloat(value) || !availableMaxAmount) {
        return false;
      }
      if (!VALID_FLOAT_REGEX.test(value)) {
        return false;
      }
      if (
        BigInt(numerateAmount({ amount: value, decimals: selectedToken.decimals })) >
        availableMaxAmount
      ) {
        return false;
      }
      return true;
    },
    [availableMaxAmount, selectedToken.decimals],
  );

  const onCloseWithReset = useCallback(() => {
    setTransferAmount('');
    setSelectedToken({
      address: ZERO_ADDRESS,
      decimals: tokenAddressToToken[ZERO_ADDRESS].decimals,
    });
    onClose();
  }, [onClose, setTransferAmount, tokenAddressToToken]);

  const afterTx = useCallback(() => {
    mutateBalances();
    onCloseWithReset();
  }, [onCloseWithReset, mutateBalances]);

  const tx = useMemo(
    () =>
      wallet?.account && selectedToken.address && chatProfile?.address
        ? {
            name: 'transfer',
            ...(selectedToken.address === ZERO_ADDRESS
              ? {
                  from: wallet?.account,
                  to: chatProfile?.address,
                  value: numeratedAmount,
                }
              : {
                  from: wallet?.account,
                  to: selectedToken.address,
                  value: 0n,
                  abi: {
                    inputs: [
                      {
                        internalType: 'address',
                        name: 'recipient',
                        type: 'address',
                      },
                      {
                        internalType: 'uint256',
                        name: 'amount',
                        type: 'uint256',
                      },
                    ],
                    name: 'transfer',
                    outputs: [
                      {
                        internalType: 'bool',
                        name: '',
                        type: 'bool',
                      },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                  },
                  params: [chatProfile.address, numeratedAmount],
                }),
          }
        : undefined,
    [numeratedAmount, chatProfile?.address, selectedToken.address, wallet?.account],
  );

  const { send, inFlight } = useWeb3Transaction({
    tx,
    and: {
      then: afterTx,
    },
    wait: true,
  });

  const isTransferDisabled = useMemo(() => {
    if (!validateTransferAmount(transferAmount)) {
      return true;
    }
    if (inFlight) {
      return true;
    }
    return false;
  }, [transferAmount, validateTransferAmount, inFlight]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const handleKeyup = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;
      onCloseWithReset();
    };

    document.addEventListener('keyup', handleKeyup);
    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [onCloseWithReset, open]);

  return (
    <Transition.Root show={open}>
      <Transition.Child
        enter="ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 transition-all bg-black/50 backdrop-blur z-[1500]"
          onClick={() => onCloseWithReset()}
        />
      </Transition.Child>

      <div className="--chat-dialog send-token fixed inset-0 z-[1500] overflow-y-auto p-4 flex justify-center items-center sm:p-6 md:p-20 pointer-events-none">
        <Transition.Child
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="w-fit h-fit px-[32px] py-[29px] flex flex-col gap-5 rounded-xl pointer-events-auto bg-gray-900">
            <p className="text-center font-semibold text-lg">
              <Trans
                t={t}
                i18nKey="How much would you like to send to <Username>{{nickname}}</Username>?"
                components={{
                  Username: <span className="text-teal-300" />,
                }}
                values={{ nickname: chatProfile?.nickname || nickname }}
              />
            </p>

            <div className="max-w-xs m-auto">
              <TokenAmountInputV2
                address={selectedToken.address}
                balance={!balances ? 0 : balance}
                value={transferAmount}
                onValueChange={setTransferAmount}
                onTokenAddressChange={(a: string) =>
                  setSelectedToken({
                    address: a,
                    decimals: tokenAddressToToken[a].decimals,
                  })
                }
                placeholder="0"
              />
            </div>
            <div className="flex gap-[15px] justify-center">
              <button
                className="w-[132px] py-[13.5px] rounded-lg  transition-colors text-[16px] leading-[21px] font-medium tracking-tight hover:opacity-70 bg-gray-800 text-gray-300"
                onClick={() => onCloseWithReset()}
              >
                {tc('Cancel')}
              </button>
              <button
                className={clsx(
                  'w-[132px] py-[13.5px] rounded-lg transition-colors text-[16px] leading-[21px] font-medium tracking-tight hover:opacity-70',
                  isTransferDisabled
                    ? 'bg-gray-600 text-gray-400 cursor-disabled'
                    : 'bg-teal-500 text-white',
                )}
                onClick={
                  isTransferDisabled
                    ? undefined
                    : () => {
                        send();
                      }
                }
              >
                {t('Send')}
              </button>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};
