import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Icon } from '@iconify/react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { DCAPriceConditionSliderV2 } from '@/components/dca/DCAPriceConditionSliderV2';
import { PriceConditionPercentageInputV2 } from '@/components/dca/PriceConditionPercentageInputV2';
import TokenIconV2 from '@/components/TokenIconV2';
import TokenSymbol from '@/components/TokenSymbol';
import { RefreshIcon } from '@/components/vector';
import { DIALOG_BACKGROUND_COLOR } from '@/defines/consts';
import { useQuote } from '@/hooks/use-quote';
import useToken from '@/hooks/use-token';
import { dressTokenCount } from '@/utils/dress-token-count';
import numerateAmount from '@/utils/numerateAmount';
import { reversePercentage } from '@/utils/reverse-percentage';

import type { PriceConditionV2 } from '@/components/dca/DCAPriceConditionV2';

export type PriceConditionDialogProps = {
  open: boolean;
  onClose: () => void;
  inputTokenAddress: string | null;
  outputTokenAddress: string | null;
  amountPerOrder: number;
  initialPriceCondition?: PriceConditionV2 | null;
  onConfirm: (priceCondition: PriceConditionV2) => void;
};

export function PriceConditionDialog({
  open,
  onClose,
  inputTokenAddress,
  outputTokenAddress,
  amountPerOrder,
  initialPriceCondition,
  onConfirm,
}: PriceConditionDialogProps) {
  const { t: td } = useTranslation('dca');
  const { t: tc } = useTranslation('common');

  const tokenIn = useToken(inputTokenAddress);
  const tokenOut = useToken(outputTokenAddress);

  const { quoteLoading, quoteResult, invalidateQuote } = useQuote({
    tokenInAddress: inputTokenAddress,
    tokenOutAddress: outputTokenAddress,
    amountIn: !tokenIn
      ? ''
      : numerateAmount({ amount: amountPerOrder, decimals: tokenIn.decimals }),
    autoRefresh: false,
    isPaused: !open,
  });

  const quotedAmount = useMemo(() => {
    if (!tokenOut?.decimals || !quoteResult?.quote) return null;

    return (
      Math.max(
        +quoteResult.quote.estimatedAmountOutAfterFee,
        +quoteResult.quote.estimatedAmountOut - +quoteResult.quote.swapscannerFee,
      ) /
      10 ** +tokenOut.decimals
    );
  }, [quoteResult?.quote, tokenOut?.decimals]);

  const quoteRate = useMemo(() => {
    if (!quotedAmount || !quoteResult?.tokenInAmount || !tokenIn?.decimals) return null;

    const tokenInAmount = +quoteResult.tokenInAmount / 10 ** +tokenIn.decimals;

    return quotedAmount / tokenInAmount;
  }, [quotedAmount, quoteResult, tokenIn]);

  useEffect(() => {
    if (!initialPriceCondition && open) {
      invalidateQuote();
    }
  }, [open, initialPriceCondition, invalidateQuote]);

  const initialRangePercentage = useMemo(
    () => ({
      lowerBound: initialPriceCondition?.minPercentage ?? -50,
      upperBound: initialPriceCondition?.maxPercentage ?? 100,
    }),
    [initialPriceCondition],
  );

  const [rangePercentage, setRangePercentage] = useState<[number | null, number | null]>([
    initialRangePercentage.lowerBound,
    initialRangePercentage.upperBound,
  ]);

  const [rateReversed, setRateReversed] = useState(false);

  const handleCancel = useCallback(() => {
    onClose();
    setRangePercentage([initialRangePercentage.lowerBound, initialRangePercentage.upperBound]);
  }, [onClose, initialRangePercentage]);

  const isRangeValid = useMemo(() => {
    if (rangePercentage[0] === null || rangePercentage[1] === null) {
      return false;
    }

    if (Math.abs(rangePercentage[0] - rangePercentage[1]) < 2) {
      return false;
    }

    return true;
  }, [rangePercentage]);

  const handleConfirm = useCallback(() => {
    if (
      !quotedAmount ||
      quoteLoading ||
      !tokenIn ||
      !tokenOut ||
      !quoteRate ||
      rangePercentage[0] === null ||
      rangePercentage[1] === null ||
      !isRangeValid
    ) {
      return;
    }

    onConfirm({
      tokenIn,
      tokenOut,
      quoteRate,
      rateReversed,
      quotedAmount,
      minPercentage: rangePercentage[0],
      maxPercentage: rangePercentage[1],
      minAmountOutPerCycle: BigInt(
        Math.floor(
          quotedAmount *
            (1 +
              (!rateReversed ? rangePercentage[0] : reversePercentage(rangePercentage[1])) / 100) *
            10 ** +tokenOut.decimals,
        ),
      ),
      maxAmountOutPerCycle: BigInt(
        Math.ceil(
          quotedAmount *
            (1 +
              (!rateReversed ? rangePercentage[1] : reversePercentage(rangePercentage[0])) / 100) *
            10 ** +tokenOut.decimals,
        ),
      ),
    });
    onClose();
  }, [
    tokenIn,
    tokenOut,
    quoteRate,
    quoteLoading,
    onClose,
    onConfirm,
    quotedAmount,
    rangePercentage,
    isRangeValid,
    rateReversed,
  ]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[1500] overflow-y-auto p-4 sm:p-6 md:p-20"
        onClose={handleCancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className={clsx('fixed inset-0 transition-all ', DIALOG_BACKGROUND_COLOR)}
          />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="mx-auto max-w-lg rounded-2lg transition-all fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-900">
            <div className="relative">
              <div className="p-6 flex justify-between items-start">
                <div className="flex flex-col space-y-1">
                  <h4 className="mt-1 text-xl font-semibold text-white">
                    {td('Price Condition Settings')}
                  </h4>
                  <p className="mt-1 text-sm text-gray-400">
                    {td('You can set the range based on the current ratio.')}
                  </p>
                </div>

                <button className="hover:opacity-70 mt-2" onClick={handleCancel}>
                  <XIcon className="h-5 w-5 text-gray-400" />
                </button>
              </div>

              <div className="flex flex-col items-center py-5 px-6 space-y-6">
                <div className="flex items-center self-start">
                  <div className="text-sm text-gray-400 flex flex-wrap items-center space-x-1">
                    <span>{td('Current Rate')}:</span>
                    <button
                      className="hover:opacity-70 rounded-full"
                      onClick={() => setRateReversed((p) => !p)}
                    >
                      <Icon className="h-3 w-3 text-gray-400" icon="eva:swap-fill" />
                    </button>{' '}
                    <TokenIconV2 width={16} height={16} address={inputTokenAddress} />
                    <span>
                      {!rateReversed
                        ? 1
                        : quotedAmount === null
                        ? '-'
                        : dressTokenCount(+amountPerOrder / quotedAmount)}
                    </span>
                    <span>
                      <TokenSymbol address={inputTokenAddress} />
                    </span>
                    <span>≈</span>
                    <TokenIconV2 width={16} height={16} address={outputTokenAddress} />
                    <span>
                      {!rateReversed
                        ? quotedAmount === null
                          ? '-'
                          : dressTokenCount(quotedAmount / +amountPerOrder)
                        : 1}
                    </span>
                    <span>
                      <TokenSymbol address={outputTokenAddress} />
                    </span>
                    <button
                      className="hover:opacity-70"
                      onClick={() => {
                        if (quoteLoading) return;

                        invalidateQuote();
                      }}
                      disabled={quoteLoading}
                    >
                      <RefreshIcon
                        className={clsx('h-4 w-4 text-gray-400', {
                          'animate-spin': quoteLoading,
                        })}
                      />
                    </button>
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="flex-1">
                    <PriceConditionPercentageInputV2
                      percentage={rangePercentage[0]}
                      minimum={-90}
                      maximum={900}
                      quoteRate={quoteRate}
                      tokenIn={tokenIn}
                      tokenOut={tokenOut}
                      rateReversed={rateReversed}
                      onChange={(v) => setRangePercentage([v, rangePercentage[1]])}
                    />
                  </div>
                  <span className={clsx('px-3 text-[28px] font-semibold')}>~</span>
                  <div className="flex-1">
                    <PriceConditionPercentageInputV2
                      percentage={rangePercentage[1]}
                      minimum={-90}
                      maximum={900}
                      quoteRate={quoteRate}
                      tokenIn={tokenIn}
                      tokenOut={tokenOut}
                      rateReversed={rateReversed}
                      onChange={(v) => setRangePercentage([rangePercentage[0], v])}
                    />
                  </div>
                </div>

                <div className="w-full">
                  <DCAPriceConditionSliderV2
                    values={rangePercentage}
                    setValues={(lower, upper) => setRangePercentage([lower, upper])}
                  />
                </div>
              </div>
            </div>

            <div className="px-5 py-3 flex justify-end space-x-3 border-t border-gray-700">
              <button
                className="px-3 py-2.5 text-sm font-semibold rounded-lg hover:opacity-70 bg-gray-800 text-white"
                onClick={handleCancel}
              >
                {tc('Cancel')}
              </button>
              <button
                className="px-3 py-2.5 text-sm font-semibold rounded-lg hover:opacity-70 disabled:!bg-gray-400 disabled:!cursor-not-allowed disabled:hover:opacity-100 bg-teal-400 text-gray-800"
                disabled={!quotedAmount || quoteLoading || !isRangeValid}
                onClick={handleConfirm}
              >
                {tc('Confirm')}
              </button>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
