export const LOCAL_STORAGE_KEYS = {
  currency: 'currency',
  currencyV2: 'currency-v2',
  currencyType: 'currencyType',
  currencyTypeV2: 'currencyType-v2',
  settings: 'settings',

  walletType: 'walletType',
  klipAccount: 'klipAccount',
  addressBook: '@address-book',

  swapSlippage: 'swap.slippage',
  swapMode: 'swap.mode',
  swapFeeIncluded: 'swap.feeIncluded',
  swapTokenInAddress: 'swap.tokenInAddress',
  swapTokenOutAddress: 'swap.tokenOutAddress',

  stakingSlippage: 'staking.slippage',

  walletTinyHidden: '@tiny-hidden-v1',
  walletExternalStakingTinyHidden: '@external-staking-tiny-hidden-v1',

  favoriteTokens: '@favorite-tokens',
  starredPairs: '@starred-paris', // typo😿. but we can't change it because it's already used in local storage

  chatAccessToken: '@chat-access-tokens',
  iframeChart: (suffix: string) => `${suffix}.chart-info`,
  iframeCexPriceInfo: (suffix: string) => `${suffix}.cex-price-info`,
  multiChartHighlightDismissed: 'dismiss-hightlight', // typo😿.

  hideQ3RoadmapBanner: 'hide-q3-roadmap-banner',
  hideQ4RoadmapBanner: 'hide-q4-roadmap-banner',

  hideUSDCDepeggingBanner: 'hide-usdc-depegging-banner',
  hideOrbitHackingBanner: 'hide-orbit-hacking-banner',
  hideReleaseGCKLAYBanner: 'hide-release-gcklay-banner',
  hideServiceMaintenanceBanner: 'hide-service-maintenance-banner',

  viewByTokenCount: '@view-by-token-count-v1',
  showAllocationChart: '@show-allocation-chart-v1',
  showAllocationChartV2: '@show-allocation-chart-v2',

  proMode: '@portfolio-pro-mode',

  simplifiedMode: '@portfolio-simplified-mode',
  useTokenAmountInsteadOfValue: '@portfolio-use-token-amount-instead-of-value',

  panelFlags: '@panel-flags',
  payWithSCNR: '@pay-with-scnr',

  viewOnlyOngoingDCAOrders: '@view-only-ongoing-dca-orders',
};
