import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import TokenAmountInputV2 from '@/components/TokenAmountInputV2';
import { useWalletContext } from '@/context/wallet';
import { ApproveABI, UINT256_MAX_BIGINT, VALID_FLOAT_REGEX } from '@/defines/consts';
import { ZERO_ADDRESS } from '@/defines/token-address';
import useBalancesV1 from '@/hooks/use-balances-v1';
import { useTokenDonation } from '@/hooks/use-token-donation';
import useTokens from '@/hooks/use-tokens';
import { TransactionSpec, useWeb3Transaction } from '@/lib/useWeb3Transaction';
import bigIntMax from '@/utils/bigIntMax';
import denominateAmount from '@/utils/denominateAmountV2';
import { getTokenAllowances } from '@/utils/get-token-allowances';
import { makeNaNToBI } from '@/utils/makeNaNToBI';
import numerateAmount from '@/utils/numerateAmount';

import type { ChatProfile } from '@/components/pro-chat/types';

export type ChatTokenDonationDialogProps = {
  open: boolean;
  onClose: () => void;
  chatProfile: ChatProfile | null;
  nickname: string;
};

export const ChatTokenDonationDialog: React.FC<ChatTokenDonationDialogProps> = ({
  open,
  chatProfile,
  nickname,
  onClose,
}) => {
  const { t } = useTranslation('chat');
  const { t: tc } = useTranslation('common');

  const { wallet } = useWalletContext();

  const { tokenAddressToToken } = useTokens({ withImaginaryFiats: true });

  const [needApproval, setNeedApproval] = useState(false);
  const [tokenAllowance, setTokenAllowance] = useState<bigint | null>(null);
  const [needFeeTokenApproval, setNeedFeeTokenApproval] = useState(false);
  const [feeTokenAllowance, setFeeTokenAllowance] = useState<bigint | null>(null);

  const { balances, mutate: mutateBalances } = useBalancesV1({
    skip: !open,
    account: wallet?.account ?? null,
  });

  const { data: tokenDonationInfo } = useTokenDonation({
    skip: !open,
  });

  const tokenDonationContract = useMemo(() => {
    if (!tokenDonationInfo) return null;

    return tokenDonationInfo.contract;
  }, [tokenDonationInfo]);

  const feeInfo = useMemo(() => {
    if (!tokenDonationInfo) return null;

    return tokenDonationInfo.feeInfo;
  }, [tokenDonationInfo]);

  const [selectedToken, setSelectedToken] = useState<{ address: string; decimals: string }>({
    address: ZERO_ADDRESS,
    decimals: tokenAddressToToken[ZERO_ADDRESS]?.decimals ?? '18',
  });

  const feeToken = useMemo(() => {
    if (!feeInfo) return null;

    switch (feeInfo.feeMode.enum) {
      case 'RATIO':
        return tokenAddressToToken[selectedToken.address.toLowerCase()];
      case 'FIXED':
        return tokenAddressToToken[feeInfo.feeToken.toLowerCase()];
    }

    return null;
  }, [feeInfo, tokenAddressToToken, selectedToken]);

  const feeTokenBalance = useMemo(() => {
    if (!balances || !feeToken?.address) return 0n;
    if (!balances[feeToken.address]) return 0n;

    return BigInt(balances[feeToken.address]);
  }, [balances, feeToken?.address]);

  const [transferAmount, setTransferAmount] = useState<string>('');

  const transferBIAmount = useMemo(() => {
    if (!transferAmount || !selectedToken) return 0n;

    return BigInt(numerateAmount({ amount: transferAmount, decimals: selectedToken.decimals }));
  }, [transferAmount, selectedToken]);

  useEffect(() => setTransferAmount(''), [selectedToken.address]);

  const feeAmount = useMemo(() => {
    if (!feeInfo || !feeToken) return 0n;

    if (feeInfo.feeMode.enum === 'RATIO' && Number(transferAmount)) {
      return BigInt(
        numerateAmount({
          amount:
            (Number(transferAmount) * Number(feeInfo.feeNumerator)) /
            Number(feeInfo.feeDenominator),
          decimals: feeToken.decimals,
        }),
      );
    }

    if (feeInfo.feeMode.enum === 'FIXED') return BigInt(feeInfo.feeAmount);

    return 0n;
  }, [feeInfo, transferAmount, feeToken]);

  const feeAmountAppliedDecimals = useMemo(() => {
    if (!feeToken || !feeAmount) return '0';

    return (
      denominateAmount({
        amount: feeAmount,
        decimals: feeToken.decimals,
        removeTrailingZeros: true,
      }) || '0'
    );
  }, [feeToken, feeAmount]);

  const balance = useMemo(() => {
    if (!selectedToken || !balances) return 0n;

    return makeNaNToBI(balances[selectedToken.address], 0n);
  }, [balances, selectedToken]);

  const balanceAppliedDecimals = useMemo(() => {
    if (!tokenAddressToToken || !selectedToken || !tokenAddressToToken[selectedToken.address])
      return 0;

    return Number(balance) / 10 ** +selectedToken.decimals;
  }, [balance, tokenAddressToToken, selectedToken]);

  const availableMaxAmount = useMemo(() => {
    if (!selectedToken || !balance) return;

    let maxBalance = balance;

    if (selectedToken.address === ZERO_ADDRESS) {
      const safeAmount =
        maxBalance - BigInt(10 ** Number(tokenAddressToToken[ZERO_ADDRESS].decimals));
      maxBalance = bigIntMax(safeAmount, 0n);
    }

    return maxBalance;
  }, [selectedToken, balance, tokenAddressToToken]);

  const getTokenAllowance = useCallback(
    async (token: string) => {
      if (!wallet || !wallet.account || !tokenDonationContract) return null;

      const { allowances } = await getTokenAllowances([
        {
          token,
          account: wallet.account,
          contract: tokenDonationContract.address,
        },
      ]);

      return BigInt(allowances.find(({ token: _token }) => token === _token)?.allowance ?? 0);
    },
    [wallet, tokenDonationContract],
  );

  useEffect(() => {
    (async () => {
      if (!wallet || !wallet.account || !tokenDonationContract || !feeToken) return;
      if (feeToken.address === ZERO_ADDRESS) return setNeedFeeTokenApproval(false);

      const allowance = await getTokenAllowance(feeToken.address);
      if (allowance === 0n) setNeedFeeTokenApproval(true);
      setFeeTokenAllowance(allowance);
    })();
  }, [wallet, tokenDonationContract, feeToken, getTokenAllowance]);

  const [txSpec, setTXSpec] = useState<Partial<TransactionSpec> | undefined>(undefined);
  const [approveTx, setApproveTx] = useState<Partial<TransactionSpec> | undefined>(undefined);

  const [untransferableReason, setUntransferableReason] = useState<string | undefined>(undefined);

  const onCloseWithReset = useCallback(() => {
    setTransferAmount('');
    setSelectedToken({
      address: ZERO_ADDRESS,
      decimals: tokenAddressToToken[ZERO_ADDRESS].decimals,
    });
    onClose();
    setNeedApproval(false);
    setApproveTx(undefined);
    setTXSpec(undefined);
    setUntransferableReason(undefined);
  }, [onClose, setTransferAmount, tokenAddressToToken]);

  const afterTx = useCallback(() => {
    mutateBalances();
    onCloseWithReset();
  }, [onCloseWithReset, mutateBalances]);

  const afterApproveTx = useCallback(async () => {
    if (!feeToken) return;

    const allowance = await getTokenAllowance(
      needFeeTokenApproval ? feeToken.address : selectedToken.address,
    );

    if (needFeeTokenApproval) {
      if (allowance === 0n) setNeedFeeTokenApproval(true);
      setFeeTokenAllowance(allowance);

      setNeedFeeTokenApproval(false);
    } else {
      if (allowance === 0n) setNeedApproval(true);
      setTokenAllowance(allowance);

      setNeedApproval(false);
    }
  }, [
    needFeeTokenApproval,
    setNeedFeeTokenApproval,
    setFeeTokenAllowance,
    getTokenAllowance,
    feeToken,
    selectedToken,
  ]);

  const { send: sendApproval, inFlight: inFlightApproval } = useWeb3Transaction({
    tx: approveTx as TransactionSpec,
    and: {
      then: afterApproveTx,
    },
    wait: true,
  });

  const { send, inFlight } = useWeb3Transaction({
    tx: txSpec as TransactionSpec,
    and: {
      then: afterTx,
    },
    wait: true,
  });

  useEffect(() => {
    if (!wallet || !wallet.account || !tokenDonationContract || !feeToken) return;

    setApproveTx({
      name: 'approve',
      from: wallet.account,
      to: needFeeTokenApproval ? feeToken.address : selectedToken.address,
      abi: ApproveABI,
      params: [tokenDonationContract.address, UINT256_MAX_BIGINT],
      value: '0',
    });
  }, [wallet, selectedToken.address, tokenDonationContract, feeToken, needFeeTokenApproval]);

  const isTransferable = useMemo(() => {
    if (!transferAmount || !parseFloat(transferAmount) || !availableMaxAmount) return false;
    if (!VALID_FLOAT_REGEX.test(transferAmount)) return false;
    if (inFlight || inFlightApproval) return false;
    if (!feeInfo || !feeToken) return false;
    if (needApproval || needFeeTokenApproval) return false;

    // Check fee token allowance
    if (feeToken.address !== ZERO_ADDRESS && (feeTokenAllowance || 0n) < feeAmount) {
      setNeedFeeTokenApproval(true);
      return false;
    }

    // Check transfer token allowance
    if (
      selectedToken.address !== ZERO_ADDRESS &&
      (tokenAllowance || 0n) <
        transferBIAmount + (selectedToken.address === feeToken.address ? feeAmount : 0n)
    ) {
      setNeedApproval(true);
      return false;
    }

    // Check fee token balance
    if (feeTokenBalance < feeAmount) {
      setUntransferableReason(t('Insufficient fee token balance'));
      return false;
    }

    // Check by comparing the fee + amount to the balance, if the fee token and the selected token are the same
    if (
      availableMaxAmount <
      transferBIAmount + (selectedToken.address === feeToken.address ? feeAmount : 0n)
    ) {
      setUntransferableReason(t('Insufficient token balance'));
      return false;
    }

    setUntransferableReason(undefined);
    return true;
  }, [
    transferAmount,
    availableMaxAmount,
    feeToken,
    inFlight,
    inFlightApproval,
    feeInfo,
    needApproval,
    needFeeTokenApproval,
    feeTokenAllowance,
    tokenAllowance,
    feeTokenBalance,
    transferBIAmount,
    selectedToken.address,
    feeAmount,
    setNeedFeeTokenApproval,
    setNeedApproval,
    t,
  ]);

  useEffect(() => {
    if (!wallet || !wallet.account) {
      return setTXSpec({
        name: 'error-wallet',
      });
    }

    if (!selectedToken.address) {
      return setTXSpec({
        name: 'error-token',
      });
    }

    if (!chatProfile || !chatProfile.address) {
      return setTXSpec({
        name: 'error-chat-profile',
      });
    }

    if (!transferBIAmount) {
      return setTXSpec({
        name: 'error-amount',
      });
    }

    if (!tokenDonationContract) {
      return setTXSpec({
        name: 'error-token-donation-contract',
      });
    }

    if (!feeInfo) {
      return setTXSpec({
        name: 'error-token-donation-fee-info',
      });
    }

    if (!feeToken) {
      return setTXSpec({
        name: 'error-token-donation-fee-token',
      });
    }

    if (!isTransferable) {
      return setTXSpec({
        name: 'error-unknown',
      });
    }

    let value = 0n;

    if (feeInfo.feeMode.enum === 'RATIO' && selectedToken.address === ZERO_ADDRESS)
      value = transferBIAmount;

    if (feeInfo.feeMode.enum === 'FIXED') {
      if (selectedToken.address === ZERO_ADDRESS) value += transferBIAmount;
      if (feeToken.address === ZERO_ADDRESS) value += feeAmount;
    }

    setTXSpec({
      name: 'donate',
      from: wallet.account,
      to: tokenDonationContract.address,
      abi: tokenDonationContract.abi.donate,
      params: [selectedToken.address, chatProfile.address, transferBIAmount],
      value,
    });
  }, [
    transferBIAmount,
    selectedToken.address,
    tokenDonationContract,
    chatProfile,
    wallet,
    feeAmount,
    feeInfo,
    isTransferable,
    feeToken,
  ]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const handleKeyup = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;
      onCloseWithReset();
    };

    document.addEventListener('keyup', handleKeyup);
    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [onCloseWithReset, open]);

  return (
    <Transition.Root show={open}>
      <Transition.Child
        enter="ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 transition-all bg-black/50 backdrop-blur z-[1500]"
          onClick={() => onCloseWithReset()}
        />
      </Transition.Child>

      <div className="--chat-dialog send-token fixed inset-0 z-[1500] overflow-y-auto p-4 flex justify-center items-center sm:p-6 md:p-20 pointer-events-none">
        <Transition.Child
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="w-fit h-fit px-[32px] py-[29px] flex flex-col gap-4 rounded-xl pointer-events-auto bg-gray-900">
            <div>
              <p className="text-center font-semibold text-lg">
                <Trans
                  t={t}
                  i18nKey="How much would you like to send to <Username>{{nickname}}</Username>?"
                  components={{
                    Username: <span className="text-teal-300" />,
                  }}
                  values={{ nickname: chatProfile?.nickname || nickname }}
                />
              </p>

              {feeInfo && feeToken && (
                <p className="text-sm text-center mt-1 text-[13px] text-gray-400">
                  {feeInfo.feeMode.enum === 'FIXED' ? (
                    <Trans
                      t={t}
                      i18nKey={
                        feeInfo.burnMode.enum === 'BURN'
                          ? '{{amount}} {{symbol}} is burned for a fee'
                          : '{{amount}} {{symbol}} is used as the fee'
                      }
                      values={{
                        amount: feeAmountAppliedDecimals,
                        symbol: feeToken.symbol,
                      }}
                    />
                  ) : (
                    feeInfo.feeMode.enum === 'RATIO' && (
                      <Trans
                        t={t}
                        i18nKey={
                          feeInfo.burnMode.enum === 'BURN'
                            ? '{{percent}}% of the tokens you are sending will be burned as a fee'
                            : "{{percent}}% of the tokens you're sending will be used as a fee"
                        }
                        values={{
                          percent: (
                            (Number(feeInfo.feeNumerator) / Number(feeInfo.feeDenominator)) *
                            100
                          ).toLocaleString(undefined, { maximumFractionDigits: 2 }),
                        }}
                      />
                    )
                  )}
                </p>
              )}
            </div>

            <div className="max-w-xs m-auto flex flex-col space-y-2">
              <TokenAmountInputV2
                address={selectedToken.address}
                balance={!balances ? 0 : balanceAppliedDecimals}
                value={transferAmount}
                onValueChange={(value: string) => {
                  setTransferAmount(value);
                }}
                onTokenAddressChange={async (token: string) => {
                  setSelectedToken({
                    address: token,
                    decimals: tokenAddressToToken[token].decimals,
                  });

                  const allowance = await getTokenAllowance(token);
                  if (allowance === 0n) setNeedApproval(true);
                  setTokenAllowance(allowance);
                }}
                placeholder="0"
              />
              {feeInfo && feeToken && (
                <p className="text-[13px] text-left ml-4 mt-2 text-gray-400">
                  <Trans
                    t={t}
                    i18nKey="Fee: {{feeAmount}} {{symbol}}"
                    values={{
                      feeAmount: feeAmountAppliedDecimals,
                      symbol: feeToken.symbol,
                    }}
                  />
                </p>
              )}
            </div>

            <div className="flex flex-col space-y-2">
              <div className="flex gap-[15px] justify-center">
                <button
                  className="w-[132px] py-[13.5px] rounded-lg  transition-colors text-[16px] leading-[21px] font-medium tracking-tight hover:opacity-70 bg-gray-800 text-gray-300"
                  onClick={() => onCloseWithReset()}
                >
                  {tc('Cancel')}
                </button>
                <button
                  className={clsx(
                    'w-[132px] py-[13.5px] rounded-lg transition-colors text-[16px] leading-[21px] font-medium tracking-tight hover:opacity-70',
                    !inFlightApproval &&
                      !inFlight &&
                      (needFeeTokenApproval || needApproval || isTransferable)
                      ? 'bg-teal-500 text-white'
                      : 'bg-gray-600 text-gray-400 cursor-disabled',
                  )}
                  onClick={() => {
                    if (inFlightApproval || inFlight) return;

                    if (needFeeTokenApproval || needApproval) {
                      sendApproval();
                    } else if (isTransferable) {
                      send();
                    }
                  }}
                >
                  {needFeeTokenApproval
                    ? t('Fee Approve')
                    : needApproval
                    ? t('Approve')
                    : t('Send')}
                </button>
              </div>

              {untransferableReason && (
                <p className="text-xs text-center text-red-500">{untransferableReason}</p>
              )}
              {!feeInfo && (
                <span className="text-red-500 text-xs text-center">
                  {t('The token donation feature is currently disabled')}
                </span>
              )}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};
