import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Fragment, useCallback, useMemo, useRef } from 'react';

import TokenIconV2 from '@/components/TokenIconV2';
import { AddWalletIcon, LinkIcon } from '@/components/vector';
import { useDialog } from '@/context/dialog';
import { useWalletContext } from '@/context/wallet';
import { DIALOG_BACKGROUND_COLOR } from '@/defines/consts';
import { SCNR_ADDRESS, ZERO_ADDRESS } from '@/defines/token-address';
import useForex from '@/hooks/use-forex';

import type { TokenWithInformation } from '@/lib/tokens';

export type TokenDetailDialogProps = {
  open: boolean;
  token: TokenWithInformation | null;
  isMyWallet?: boolean;
  onClose: () => void;
};

export default function TokenDetailDialog({
  open,
  token,
  isMyWallet,
  ...props
}: TokenDetailDialogProps) {
  const router = useRouter();
  const { wallet } = useWalletContext();
  const dummyButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('wallet');
  const { t: tc } = useTranslation('common');

  const { usdToReadableCurrency } = useForex();

  const { openTokenTransferDialog } = useDialog();

  const onClose = useCallback(() => {
    props.onClose();
  }, [props]);

  const valuation = useMemo(() => {
    return usdToReadableCurrency((token?.priceUSDC ?? 0) * (token?.balance ?? 0));
  }, [usdToReadableCurrency, token]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[1500] overflow-y-auto p-4 sm:p-6 md:p-20"
        onClose={onClose}
        initialFocus={dummyButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className={clsx('fixed inset-0 transition-all', DIALOG_BACKGROUND_COLOR)}
          />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="mx-auto max-w-[343px] transform overflow-hidden rounded-xl shadow-2xl transition-all bg-gray-900 ring-1 ring-gray-50/5">
            <div className="flex flex-col justify-center items-center pt-[31px] pb-[19px] px-[24px]">
              {/* Dummy button for empty focus */}
              <button ref={dummyButtonRef} className="hidden"></button>

              <TokenIconV2
                className="h-[72px] w-[72px]"
                address={token?.address}
                width={72}
                height={72}
              />

              <div className="mt-5 flex items-center">
                <span className="font-medium text-lg">
                  {`${
                    token?.balance?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 3,
                    }) ?? '0'
                  } ${token?.symbol ?? ''}`}
                </span>

                {token?.address !== ZERO_ADDRESS && (
                  <span className="ml-1">
                    <button
                      className="flex items-center justify-center"
                      data-tooltip-id="global-tooltip"
                      data-tooltip-content={
                        tc('Add {{token}} to your wallet', {
                          token: token?.symbol,
                        }) +
                        (wallet?.type
                          ? !wallet?.watchAsset
                            ? ': ' + tc('Unsupported wallet!')
                            : ''
                          : ': ' + tc('Connect your wallet first!'))
                      }
                      onClick={() => {
                        if (!token) return;
                        if (!wallet?.watchAsset) return;

                        wallet
                          .watchAsset(
                            token.address,
                            token.symbol,
                            token.decimals,
                            `https://api.swapscanner.io/api/tokens/${token.address}/icon`,
                          )
                          .catch((err) => console.error('Failed to add token to wallet', err));
                      }}
                      disabled={!token?.symbol || token?.address === ZERO_ADDRESS}
                    >
                      <AddWalletIcon />
                    </button>
                  </span>
                )}
              </div>

              <div className="mt-2 flex items-center">
                <span className="text-base leading-[19px] text-gray-400">{valuation}</span>
                {token?.address !== ZERO_ADDRESS && (
                  <a
                    className="flex items-center justify-center"
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={tc('{{token}} token contract', {
                      token: token?.symbol,
                    })}
                    href={`https://kaiascope.com/token/${token?.address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkIcon />
                  </a>
                )}
              </div>

              <div className="mt-[45px] w-full flex gap-[15px]">
                <button
                  data-tooltip-id="global-tooltip"
                  data-tooltip-contetn={t('Swap {{token}} to SCNR', {
                    token: token?.symbol === 'SCNR' ? 'KAIA' : token?.symbol,
                  })}
                  className="w-full pt-[17px] pb-[15px] text-base leading-[19px] text-gray-900 font-bold bg-teal-400 rounded-xl hover:opacity-70"
                  onClick={(event) => {
                    event.stopPropagation();
                    if (!token) return;

                    onClose();
                    router.push({
                      pathname: '/swap',
                      query: {
                        ...router.query,
                        from: token.address === SCNR_ADDRESS ? ZERO_ADDRESS : token.address,
                        to: SCNR_ADDRESS,
                      },
                    });
                  }}
                >
                  {t('Swap')}
                </button>

                {isMyWallet && (
                  <button
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={t('Transfer {{token}} tokens', {
                      token: token?.symbol,
                    })}
                    className="w-full pt-[17px] pb-[15px] text-base leading-[19px] text-gray-900 font-bold bg-teal-400 rounded-xl hover:opacity-70"
                    onClick={() => {
                      onClose();
                      // FIXME: This is a hack to prevent scroll locking
                      setTimeout(() => {
                        openTokenTransferDialog({ token });
                      }, 500);
                    }}
                  >
                    {t('Transfer')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
