export const TINY_AMOUNT_THRESHOLD = 5;
export const CRITERIA_INCLUDED_IN_OTHERS = 0.01;
export const VALID_FLOAT_REGEX = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;
export const VALID_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
export const SCNR_DECIMALS = 25;
export const KAIA_DECIMALS = 18;
export const SCNR_KAIA_LP_DECIMALS = 18;
export const GCKAIA_DECIMALS = 18;
export const GCKAIA_SHARES_DECIMALS = 27;
export const UINT256_MAX_BIGINT =
  115792089237316195423570985008687907853269984665640564039457584007913129639935n;

export const TIME_WINDOW_FOR_OHLC_TYPE = {
  '1m': 1000 * 60,
  '3m': 1000 * 60 * 3,
  '5m': 1000 * 60 * 5,
  '15m': 1000 * 60 * 15,
  '30m': 1000 * 60 * 30,
  '1h': 1000 * 60 * 60,
  '2h': 1000 * 60 * 60 * 2,
  '4h': 1000 * 60 * 60 * 4,
  '12h': 1000 * 60 * 60 * 12,
  '1d': 1000 * 60 * 60 * 24,
  '1w': 1000 * 60 * 60 * 24 * 7,
};

export const ApproveABI = {
  type: 'function',
  name: 'approve',
  inputs: [
    { name: '', type: 'address' },
    { name: '', type: 'uint256' },
  ],
};

export const DIALOG_BACKGROUND_COLOR = 'bg-gray-950/50';
