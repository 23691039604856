import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useRef } from 'react';

import { useDialog } from '@/context/dialog';
import { DIALOG_BACKGROUND_COLOR } from '@/defines/consts';

export type NetworkSelectorDialogProps = {
  open: boolean;
  tokenAddress: string;
  inputChain: string;
  setNetworkState: Dispatch<SetStateAction<any>>;
  onClose: () => void;
};

export default function NetworkSelector({
  open,
  inputChain,
  tokenAddress,
  setNetworkState,
  ...props
}: NetworkSelectorDialogProps) {
  const dummyButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('common');
  const { setTokenTransferDialogNotClose } = useDialog();

  const onClose = useCallback(() => {
    props.onClose();
  }, [props]);

  // TODO: You will need to update them to use transferable network APIs for use on multiple networks.
  const transferableNetworks = ['kaia'];

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;
      setTokenTransferDialogNotClose(false);
    };

    document.addEventListener('keyup', handleKeyUp);
    return () => {
      setTokenTransferDialogNotClose(false);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [setTokenTransferDialogNotClose]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[1500] overflow-y-auto"
        onClose={onClose}
        initialFocus={dummyButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className={clsx('fixed inset-0 transition-all', DIALOG_BACKGROUND_COLOR)}
          />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={clsx(
              'bg-gray-900 ring-1 ring-slate-50/5',
              'max-w-lg w-full transform overflow-hidden rounded-xl shadow-2xl transition-all fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
            )}
          >
            <div className="w-full px-4 py-3 flex flex-row items-center justify-between">
              <label className="font-medium text-lg text-slate-200">{t('Select Network')}</label>
              <button
                className="rounded border px-1.5 py-px text-xs font-medium tracking-tight hover:opacity-70 outline-none border-slate-600 bg-slate-900 text-slate-200"
                onClick={() => onClose()}
              >
                ESC
              </button>
            </div>

            <p className="px-8 py-6 font-normal text-lg">{t('select-network-description')}</p>
            <div
              className={clsx(
                'max-h-[300px] overflow-y-scroll',
                transferableNetworks.length < 5 ? 'hide-scrollbar' : '',
              )}
            >
              {transferableNetworks.map((network) => {
                return (
                  <div
                    key={network}
                    className="flex flex-row items-center space-x-2 py-4 px-8 hover:opacity-70 hover:bg-gray-700"
                  >
                    <img
                      src={`/images/networks/${network}.png`}
                      alt="network"
                      className="w-6 h-auto"
                    />
                    <button
                      className="w-full text-left"
                      onClick={() => {
                        setNetworkState(network.toUpperCase());
                        onClose();
                      }}
                    >
                      <span className="text-2xl font-bold">{network.toUpperCase()}</span>
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="h-8" />
          </div>
        </Transition.Child>
        <style jsx>{`
          .hide-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }
          .hide-scrollbar::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera */
          }
        `}</style>
      </Dialog>
    </Transition.Root>
  );
}
