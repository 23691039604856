import { XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { Dispatch, SetStateAction, useState } from 'react';

import { StarIcon2 } from '@/components/vector';
import CopyCompletedIcon from '@/components/vector/CopyCompletedIcon';
import CopyIcon from '@/components/vector/CopyIcon';
import { useWalletContext } from '@/context/wallet';
import { DIALOG_BACKGROUND_COLOR } from '@/defines/consts';
import { copyToClipboard } from '@/utils/copy-to-clipboard';

export type AddressBookPopupProps = {
  showAddressbook: boolean;
  setShowAddressbook: (value: SetStateAction<boolean>) => void;
  setReceiverAddress: Dispatch<SetStateAction<string>>;
  addressBook: {
    name: string;
    address: string;
  }[];
};

export default function AddressBookPopup({
  showAddressbook,
  setShowAddressbook,
  setReceiverAddress,
  addressBook,
}: AddressBookPopupProps) {
  const { t } = useTranslation('addressBook');
  const { wallet } = useWalletContext();
  const [copiedIndex, setCoiedIndex] = useState<number>(-1);

  return (
    <div
      className={clsx(
        'address-book-popup z-[1600] h-screen w-screen fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all duration-300',
        DIALOG_BACKGROUND_COLOR,
        {
          'opacity-0 pointer-events-none': !showAddressbook,
          'opacity-100 pointer-events-auto': showAddressbook,
        },
      )}
      onClick={(e) => {
        e.stopPropagation();
        setShowAddressbook(false);
      }}
    >
      <div
        className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[92%] xs:max-w-[480px] w-full max-h-[420px] border border-gray-600 bg-gray-800 rounded-md z-10 p-6 transition-opacity duration-150"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex flex-row items-center justify-between mb-6">
          <div className="flex flex-row items-center space-x-2">
            <StarIcon2 className="text-yellow-500" />
            <h2 className="text-2xl font-bold text-gray-300">{t('Address book')}</h2>
          </div>
          <button className="hover:opacity-75" onClick={() => setShowAddressbook(false)}>
            <XIcon className="w-6 h-6 text-white" />
          </button>
        </div>

        <div className="address-book-popup-content overflow-scroll max-h-[300px]">
          {addressBook.length === 0 ? (
            <p className="text-center font-semibold text-gray-300">
              {t('There is no address added in address book')}
            </p>
          ) : (
            addressBook.map(({ name, address }, idx) => (
              <div key={address} className="relative">
                <div className="flex flex-row items-center justify-between space-x-4">
                  <button
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={
                      wallet?.account === address.toLocaleLowerCase()
                        ? t('Currently connected address')
                        : t('Select this address')
                    }
                    className="hover:opacity-70 border text-base text-left rounded-md w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-gray-300 truncate"
                    onClick={(e) => {
                      e.stopPropagation();

                      setReceiverAddress(address);
                      setShowAddressbook(false);
                    }}
                  >
                    {name || t('Set a name for this address')}
                  </button>
                </div>

                <div className="mt-2 mb-4 pl-2 flex flex-row items-center space-x-4">
                  <span className="text-white text-sm truncate">{address}</span>

                  <button
                    data-tooltip-id="global-tooltip"
                    data-tooltip-content={
                      copiedIndex === idx ? t('Copy Address') : t('Copy Completed')
                    }
                    className="hover:opacity-70 bg-gray-800 rounded-md"
                    onClick={() => {
                      copyToClipboard(address);

                      setCoiedIndex(idx);

                      setTimeout(() => {
                        setCoiedIndex(-1);
                      }, 3_000);
                    }}
                  >
                    {copiedIndex !== idx ? (
                      <CopyIcon className="text-gray-300 w-4 h-4 xs:w-4 xs:h-4" />
                    ) : (
                      <CopyCompletedIcon className="text-scnr w-4 h-4 xs:w-4 xs:h-4" />
                    )}
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <style jsx>{`
        .address-book-popup-content {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
        .address-book-popup-content::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera */
        }
      `}</style>
    </div>
  );
}
