import clsx from 'clsx';

import type { CSSProperties } from 'react';

type SkeletonProps = {
  className?: string;
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
  backgroundColor?: string;
};

export function Skeleton({
  className,
  width,
  height,
  backgroundColor = 'bg-gray-700',
}: SkeletonProps) {
  return (
    <div
      className={clsx(className, backgroundColor, 'animate-pulse rounded-lg')}
      style={{
        width,
        height,
      }}
    >
      <p className="invisible text-center">s</p>
    </div>
  );
}
