import clsx from 'clsx';

export default function ToggleButton({
  className = '',
  label = '',
  checked,
  setChecked,
  disabled = false,
  tooltip,
  borderAnimation,
}: {
  className?: string;
  label?: string;
  checked: boolean;
  setChecked: (value: boolean) => void;
  disabled?: boolean;
  tooltip?: string;
  borderAnimation?: string;
}) {
  return (
    <div
      className={clsx('flex items-center relative', className, disabled && 'opacity-50')}
      data-tooltip-id="global-tooltip"
      data-tooltip-content={tooltip}
    >
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only peer"
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
          checked={!disabled && checked}
          disabled={disabled}
        />
        <div className="w-10 h-6 peer-focus:outline-none rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[3px] after:left-[3px] peer-checked:after:left-[1px] after:bg-white after:rounded-full after:h-[18px] after:w-[18px] after:transition-all peer-checked:bg-teal-500"></div>
        {borderAnimation && (
          <div
            className={clsx(
              'w-10 h-6 border-2 absolute inset-0 rounded-full animate-pulse duration-100',
              borderAnimation,
            )}
          ></div>
        )}
        {label && (
          <span
            className={clsx(
              'ml-3 font-normal text-[15px] hover:opacity-70',
              checked ? 'text-gray-200' : 'text-gray-500',
            )}
          >
            {label}
          </span>
        )}
      </label>
    </div>
  );
}
