import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import AddAddressBookV2Dialog from '@/components/modal/AddressBookDialog/AddAddressBookV2';
import AddressBookV2Dialog from '@/components/modal/AddressBookDialog/AddressBookV2';
import CexPriceDisplaySetupDialog from '@/components/modal/CexPriceDisplaySetupDialog';
import ConfirmationDialog, { ConfirmationDialogProps } from '@/components/modal/ConfirmationDialog';
import NetworkSelector, { NetworkSelectorDialogProps } from '@/components/modal/NetworkSelector';
import {
  PriceConditionDialog,
  PriceConditionDialogProps,
} from '@/components/modal/PriceConditionDialog';
import SlippageSetupDialog from '@/components/modal/SlippageSetupDialog';
import StarredPairsDialog from '@/components/modal/StarredPairsDialog';
import TokenDetailDialog, { TokenDetailDialogProps } from '@/components/modal/TokenDetailDialog';
import TokenSelectorDialogV2 from '@/components/modal/TokenSelectorDialogV2';
import {
  TokenSelectorDialogV3,
  TokenSelectorDialogV3Props,
} from '@/components/modal/TokenSelectorDialogV3';
import TokenTransferDialog, {
  TokenTransferDialogProps,
} from '@/components/modal/TokenTransferDialog';
import TransferTokenDialog from '@/components/modal/TransferTokenDialog';
import {
  WalletConnectDialog,
  type WalletConnectDialogProps,
} from '@/components/modal/WalletConnectDialog';
import {
  ChatNicknameEditDialog,
  type ChatNicknameEditDialogProps,
} from '@/components/pro-chat/ChatNicknameEditDialog';
import {
  ChatSDoongELevelDialog,
  ChatSDoongELevelDialogProps,
} from '@/components/pro-chat/ChatSDoongELevelDialog';
import {
  ChatSendTokenDialog,
  ChatSendTokenDialogProps,
} from '@/components/pro-chat/ChatSendTokenDialog';
import {
  ChatTokenDonationDialog,
  ChatTokenDonationDialogProps,
} from '@/components/pro-chat/ChatTokenDonationDialog';
import { useStarredPairs } from '@/hooks/use-starred-pairs';

import type { AddAddressBookV2DialogProps } from '@/components/modal/AddressBookDialog/AddAddressBookV2';
import type { AddressBookV2DialogProps } from '@/components/modal/AddressBookDialog/AddressBookV2';
import type { CexPriceDisplaySetupDialogProps } from '@/components/modal/CexPriceDisplaySetupDialog';
import type { SlippageSetupDialogProps } from '@/components/modal/SlippageSetupDialog';
import type { StarredPairsDialogProps } from '@/components/modal/StarredPairsDialog';
import type { TokenSelectorProps } from '@/components/modal/TokenSelectorDialogV2';
import type { TransferTokenDialogProps } from '@/components/modal/TransferTokenDialog';
import type { ChatProfileDialogProps } from '@/components/pro-chat/ChatProfileDialog';

type TokenSelectorOptions = Pick<
  TokenSelectorProps,
  'label' | 'onSelectToken' | 'withImaginaryFiats'
>;
type TokenSelectorV3Options = Omit<TokenSelectorDialogV3Props, 'open' | 'onClose'>;

type StarredParisOptions = Pick<StarredPairsDialogProps, 'onSelectFromTo'>;

type TokenDetailOptions = Pick<TokenDetailDialogProps, 'token' | 'isMyWallet'>;
type TokenTransferOptions = Pick<TokenTransferDialogProps, 'token'>;

type ChatProfileOptions = Pick<
  ChatProfileDialogProps,
  'chatProfile' | 'accessToken' | 'onNicknameChange' | 'onClickClose'
>;
type ChatNicknameOptions = Pick<
  ChatNicknameEditDialogProps,
  'chatProfile' | 'accessToken' | 'onNicknameChange' | 'onClickClose'
>;
type ChatSendTokenOptions = Pick<ChatSendTokenDialogProps, 'chatProfile' | 'nickname'>;
type ChatTokenDonationOptions = Pick<ChatTokenDonationDialogProps, 'chatProfile' | 'nickname'>;
type openChatSDoongELevelOptions = Pick<
  ChatSDoongELevelDialogProps,
  'scnrBalance' | 'sdoongeLevel'
>;

type ConfirmationOptions = Pick<
  ConfirmationDialogProps,
  'variant' | 'title' | 'content' | 'cancelButton' | 'actionButton'
>;

type NetworkSelectorOptions = Pick<
  NetworkSelectorDialogProps,
  'tokenAddress' | 'inputChain' | 'setNetworkState'
>;

type AddAddressBookV2Options = Pick<
  AddAddressBookV2DialogProps,
  'checksumAddress' | 'addressBook' | 'setAddressBook'
>;

type AddressBookV2Options = Pick<
  AddressBookV2DialogProps,
  'addressBook' | 'setAddressBook' | 'routerPushHandler'
>;

type TransferTokenDialogOptions = Pick<TransferTokenDialogProps, 'transfers'>;

type CexPriceDisplaySetupDialogOptions = Pick<
  CexPriceDisplaySetupDialogProps,
  'cexPriceDisplaySetting' | 'setCexPriceDisplaySetting'
>;

type PriceConditionDialogOptions = Omit<PriceConditionDialogProps, 'open' | 'onClose'>;
type SlippageSetupDialogOptions = Pick<SlippageSetupDialogProps, 'slippage' | 'onSlippageChange'>;

export type DialogStore = {
  openTokenSelector: (options: TokenSelectorOptions) => void;
  openTokenSelectorV3: (options: TokenSelectorV3Options) => void;
  openStarredPairsDialog: (options: StarredParisOptions) => void;
  openTokenDetailDialog: (options: TokenDetailOptions) => void;
  openTokenTransferDialog: (options: TokenTransferOptions) => void;
  openConfirmationDialog: (options: ConfirmationOptions) => void;
  openWalletConnectDialog: () => void;

  openChatProfileDialog: (options: ChatProfileOptions) => void;
  openChatNicknameEditDialog: (options: ChatNicknameOptions) => void;
  openChatSendTokenDialog: (options: ChatSendTokenOptions) => void;
  openChatTokenDonationDialog: (options: ChatTokenDonationOptions) => void;
  openChatSDoongELevelDialog: (options: openChatSDoongELevelOptions) => void;
  setTokenTransferDialogNotClose: (notClose: boolean) => void;
  resetAllChatRelatedDialogs: () => void;

  openNetworkSelectorDialog: (options: NetworkSelectorOptions) => void;
  openAddAddressBookV2Dialog: (options: AddAddressBookV2Options) => void;
  openAddressBookV2Dialog: (options: AddressBookV2Options) => void;
  openTransferTokenDialog: (options: TransferTokenDialogOptions) => void;
  openCexPriceDisplaySetupDialog: (options: CexPriceDisplaySetupDialogOptions) => void;

  openPriceConditionDialog: (options: PriceConditionDialogOptions) => void;
  openSlippageSetupDialog: (options: SlippageSetupDialogOptions) => void;
} & ReturnType<typeof useStarredPairs>;

export const DialogContext = createContext<DialogStore>({
  openTokenSelector: () => {},
  openTokenSelectorV3: () => {},
  openStarredPairsDialog: () => {},
  openTokenTransferDialog: () => {},
  openTokenDetailDialog: () => {},
  openConfirmationDialog: () => {},
  openWalletConnectDialog: () => {},

  openChatProfileDialog: () => {},
  openChatNicknameEditDialog: () => {},
  openChatSendTokenDialog: () => {},
  openChatTokenDonationDialog: () => {},
  openChatSDoongELevelDialog: () => {},
  setTokenTransferDialogNotClose: () => {},
  resetAllChatRelatedDialogs: () => {},

  openNetworkSelectorDialog: () => {},
  openAddAddressBookV2Dialog: () => {},
  openAddressBookV2Dialog: () => {},
  openTransferTokenDialog: () => {},
  openCexPriceDisplaySetupDialog: () => {},

  openPriceConditionDialog: () => {},
  openSlippageSetupDialog: () => {},

  starredPairs: [],
  isStarredPair: () => false,
  toggleStarred: () => {},
  reloadStarredPairs: () => {},
});

const tokenSelectorInitialState: TokenSelectorProps = {
  open: false,
  label: '',
  onClose: () => {},
  onSelectToken: () => {},
  withImaginaryFiats: false,
};

const tokenSelectorV3InitialState: TokenSelectorDialogV3Props = {
  open: false,
  label: '',
  onClose: () => {},
  onSelectToken: () => {},
};

const starredPairsInitialState: StarredPairsDialogProps = {
  open: false,
  onClose: () => {},
  onSelectFromTo: () => {},
};

const tokenTransferInitialState: TokenTransferDialogProps = {
  open: false,
  onClose: () => {},
  token: null,
};

const confirmationInitialState: ConfirmationDialogProps = {
  open: false,
  title: '',
  content: '',
  onClose: () => {},
  actionButton: {
    label: '',
    onClick: () => {},
  },
};

const tokenDetailInitialState: TokenDetailDialogProps = {
  open: false,
  onClose: () => {},
  token: null,
};

const walletConnectInitialState: WalletConnectDialogProps = {
  open: false,
  onClose: () => {},
};

const chatProfileInitialState: ChatProfileDialogProps = {
  open: false,
  onClose: () => {},
  accessToken: '',
  chatProfile: null,
  onNicknameChange: () => {},
  onClickClose: () => {},
};

const chatNicknameInitialState: ChatNicknameEditDialogProps = {
  open: false,
  onClose: () => {},
  chatProfile: null,
  accessToken: '',
  onNicknameChange: () => {},
  onClickClose: () => {},
};

const chatSendTokenInitialState: ChatSendTokenDialogProps = {
  open: false,
  onClose: () => {},
  chatProfile: null,
  nickname: '',
};

const chatTokenDonationInitialState: ChatTokenDonationDialogProps = {
  open: false,
  onClose: () => {},
  chatProfile: null,
  nickname: '',
};

const chatSDoongELevelInitialState: ChatSDoongELevelDialogProps = {
  open: false,
  onClose: () => {},
  scnrBalance: 0,
  sdoongeLevel: '',
};

const networkSelectorInitialState: NetworkSelectorDialogProps = {
  open: false,
  onClose: () => {},
  tokenAddress: '',
  inputChain: '',
  setNetworkState: () => {},
};

const addAddressBookV2InitialState: AddAddressBookV2DialogProps = {
  open: false,
  onClose: () => {},
  checksumAddress: '',
  addressBook: [],
  setAddressBook: () => {},
};

const addressBookV2InitialState: AddressBookV2DialogProps = {
  open: false,
  onClose: () => {},
  addressBook: [],
  setAddressBook: () => {},
  routerPushHandler: () => {},
};

const transferTokenInitialState: TransferTokenDialogProps = {
  open: false,
  transfers: [],
  onClose: () => {},
};

const cexPriceDisplaySettingInitialState: CexPriceDisplaySetupDialogProps = {
  open: false,
  cexPriceDisplaySetting: {
    activate: true,
    otherCurrency: true,
    diffPercentage: true,
  },
  setCexPriceDisplaySetting: () => {},
  onClose: () => {},
};

const priceConditionInitialState: PriceConditionDialogProps = {
  open: false,
  onClose: () => {},
  inputTokenAddress: '',
  outputTokenAddress: '',
  amountPerOrder: 0,
  onConfirm: () => {},
};

const slippageSetupInitialState: SlippageSetupDialogProps = {
  open: false,
  slippage: 100,
  onSlippageChange: () => {},
  onClose: () => {},
};

export function DialogContextProvider({ children }: { children: ReactNode }) {
  const [tokenSelectorState, setTokenSelectorState] =
    useState<TokenSelectorProps>(tokenSelectorInitialState);
  const [tokenSelectorV3State, setTokenSelectorV3State] = useState<TokenSelectorDialogV3Props>(
    tokenSelectorV3InitialState,
  );
  const [starredPairsState, setStarredPairsState] =
    useState<StarredPairsDialogProps>(starredPairsInitialState);
  const [tokenTransferState, setTokenTransferState] =
    useState<TokenTransferDialogProps>(tokenTransferInitialState);
  const [confirmationState, setConfirmationState] =
    useState<ConfirmationDialogProps>(confirmationInitialState);
  const [tokenDetailState, setTokenDetailState] =
    useState<TokenDetailDialogProps>(tokenDetailInitialState);
  const [walletConnectState, setWalletConnectState] =
    useState<WalletConnectDialogProps>(walletConnectInitialState);

  // const [chatProfileState, setChatProfileState] =
  //   useState<ChatProfileDialogProps>(chatProfileInitialState);
  const [chatNicknameEditState, setChatNicknameEditState] =
    useState<ChatNicknameEditDialogProps>(chatNicknameInitialState);
  const [chatSendTokenState, setChatSendTokenState] =
    useState<ChatSendTokenDialogProps>(chatSendTokenInitialState);
  const [chatTokenDonationState, setChatTokenDonationState] =
    useState<ChatTokenDonationDialogProps>(chatTokenDonationInitialState);
  const [chatSDoongELevelState, setChatSDoongELevelState] = useState<ChatSDoongELevelDialogProps>(
    chatSDoongELevelInitialState,
  );
  const [networkSelectorState, setNetworkSelectorState] = useState<NetworkSelectorDialogProps>(
    networkSelectorInitialState,
  );
  const [addAddressBookV2State, setAddAddressBookV2State] = useState<AddAddressBookV2DialogProps>(
    addAddressBookV2InitialState,
  );
  const [addressBookV2State, setAddressBookV2State] =
    useState<AddressBookV2DialogProps>(addressBookV2InitialState);
  const [transferTokenState, setTransferTokenState] =
    useState<TransferTokenDialogProps>(transferTokenInitialState);
  const [cexPriceDisplaySettingState, setCexPriceDisplaySettingState] =
    useState<CexPriceDisplaySetupDialogProps>(cexPriceDisplaySettingInitialState);
  const [priceConditionState, setPriceConditionState] = useState<PriceConditionDialogProps>(
    priceConditionInitialState,
  );
  const [slippageSetupState, setSlippageSetupState] =
    useState<SlippageSetupDialogProps>(slippageSetupInitialState);

  const { starredPairs, isStarredPair, toggleStarred, reloadStarredPairs } = useStarredPairs();

  const openTokenSelector: DialogStore['openTokenSelector'] = useCallback(
    ({ label, onSelectToken, withImaginaryFiats }) => {
      setTokenSelectorState((prev) => ({
        ...prev,
        open: true,
        onClose: () => setTokenSelectorState((prev) => ({ ...prev, open: false })),
        label,
        onSelectToken,
        withImaginaryFiats,
      }));
    },
    [],
  );

  const openTokenSelectorV3: DialogStore['openTokenSelectorV3'] = useCallback(
    ({ label, onSelectToken, sortOption, withImaginaryFiats }) => {
      setTokenSelectorV3State((prev) => ({
        ...prev,
        open: true,
        onClose: () => setTokenSelectorV3State((prev) => ({ ...prev, open: false })),
        label,
        onSelectToken,
        sortOption,
        withImaginaryFiats,
      }));
    },
    [],
  );

  const openStarredPairsDialog: DialogStore['openStarredPairsDialog'] = useCallback(
    ({ onSelectFromTo }) => {
      setStarredPairsState((prev) => ({
        ...prev,
        open: true,
        onClose: () => setStarredPairsState((prev) => ({ ...prev, open: false })),
        onSelectFromTo,
      }));
    },
    [],
  );

  const openTokenDetailDialog: DialogStore['openTokenDetailDialog'] = useCallback(
    ({ token, isMyWallet }) => {
      setTokenDetailState((prev) => ({
        ...prev,
        open: true,
        onClose: () => setTokenDetailState((prev) => ({ ...prev, open: false })),
        token,
        isMyWallet,
      }));
    },
    [],
  );

  const openTokenTransferDialog: DialogStore['openTokenTransferDialog'] = useCallback(
    ({ token }) => {
      setTokenTransferState((prev) => ({
        ...prev,
        open: true,
        onClose: () => setTokenTransferState((prev) => ({ ...prev, open: false })),
        token,
      }));
    },
    [],
  );

  const openConfirmationDialog: DialogStore['openConfirmationDialog'] = useCallback(
    ({ variant, title, content, cancelButton, actionButton }) => {
      setConfirmationState((prev) => ({
        ...prev,
        open: true,
        onClose: () => setConfirmationState((prev) => ({ ...prev, open: false })),
        variant,
        title,
        content,
        cancelButton,
        actionButton,
      }));
    },
    [],
  );

  const openWalletConnectDialog: DialogStore['openWalletConnectDialog'] = useCallback(() => {
    setWalletConnectState((prev) => ({
      ...prev,
      open: true,
      onClose: () => setWalletConnectState((prev) => ({ ...prev, open: false })),
    }));
  }, []);

  // const openChatProfileDialog: DialogStore['openChatProfileDialog'] = useCallback(
  //   ({ chatProfile, accessToken, onNicknameChange, onClickClose }) => {
  //     setChatProfileState((prev) => ({
  //       ...prev,
  //       chatProfile,
  //       accessToken,
  //       onNicknameChange,
  //       open: true,
  //       onClickClose,
  //       onClose: () => setChatProfileState((prev) => ({ ...prev, open: false })),
  //     }));
  //   },
  //   [],
  // );

  const openChatNicknameEditDialog: DialogStore['openChatNicknameEditDialog'] = useCallback(
    ({ chatProfile, accessToken, onNicknameChange, onClickClose }) => {
      setChatNicknameEditState((prev) => ({
        ...prev,
        chatProfile,
        accessToken,
        onNicknameChange,
        open: true,
        onClose: () => setChatNicknameEditState((prev) => ({ ...prev, open: false })),
        onClickClose,
      }));
    },
    [],
  );

  const openChatSendTokenDialog: DialogStore['openChatSendTokenDialog'] = useCallback(
    ({ chatProfile, nickname }) => {
      setChatSendTokenState((prev) => ({
        ...prev,
        chatProfile,
        nickname,
        open: true,
        onClose: () => setChatSendTokenState((prev) => ({ ...prev, open: false })),
      }));
    },
    [],
  );

  const openChatTokenDonationDialog: DialogStore['openChatTokenDonationDialog'] = useCallback(
    ({ chatProfile, nickname }) => {
      setChatTokenDonationState((prev) => ({
        ...prev,
        chatProfile,
        nickname,
        open: true,
        onClose: () => setChatTokenDonationState((prev) => ({ ...prev, open: false })),
      }));
    },
    [],
  );

  const openChatSDoongELevelDialog: DialogStore['openChatSDoongELevelDialog'] = useCallback(
    ({ sdoongeLevel, scnrBalance }) => {
      setChatSDoongELevelState((prev) => ({
        ...prev,
        open: true,
        sdoongeLevel,
        scnrBalance,
        onClose: () => setChatSDoongELevelState((prev) => ({ ...prev, open: false })),
      }));
    },
    [],
  );

  const resetAllChatRelatedDialogs: DialogStore['resetAllChatRelatedDialogs'] = useCallback(() => {
    // setChatProfileState(chatProfileInitialState);
    setChatNicknameEditState(chatNicknameInitialState);
  }, []);

  const setTokenTransferDialogNotClose: DialogStore['setTokenTransferDialogNotClose'] = useCallback(
    (notClose) => {
      setTokenTransferState((prev) => ({
        ...prev,
        onClose: () => {
          notClose
            ? setNetworkSelectorState((prev) => ({ ...prev, open: false }))
            : setTokenTransferState((prev) => ({ ...prev, open: false }));
        },
      }));
    },
    [],
  );

  const openNetworkSelectorDialog: DialogStore['openNetworkSelectorDialog'] = useCallback(
    ({ inputChain, tokenAddress, setNetworkState }) => {
      setNetworkSelectorState((prev) => ({
        ...prev,
        open: true,
        onClose: () => {
          setTokenTransferDialogNotClose(false);
          setNetworkSelectorState((prev) => ({ ...prev, open: false }));
        },
        inputChain,
        tokenAddress,
        setNetworkState,
      }));
    },
    [setTokenTransferDialogNotClose],
  );

  const openAddAddressBookV2Dialog: DialogStore['openAddAddressBookV2Dialog'] = useCallback(
    ({ checksumAddress, addressBook, setAddressBook }) => {
      setAddAddressBookV2State((prev) => ({
        ...prev,
        open: true,
        onClose: () => setAddAddressBookV2State((prev) => ({ ...prev, open: false })),
        checksumAddress,
        addressBook,
        setAddressBook,
      }));
    },
    [],
  );

  const openAddressBookV2Dialog: DialogStore['openAddressBookV2Dialog'] = useCallback(
    ({ addressBook, setAddressBook, routerPushHandler }) => {
      setAddressBookV2State((prev) => ({
        ...prev,
        open: true,
        onClose: () => setAddressBookV2State((prev) => ({ ...prev, open: false })),
        addressBook,
        setAddressBook,
        routerPushHandler,
      }));
    },
    [],
  );

  const openTransferTokenDialog: DialogStore['openTransferTokenDialog'] = useCallback(
    ({ transfers }) => {
      setTransferTokenState((prev) => ({
        ...prev,
        open: true,
        transfers,
        onClose: () => setTransferTokenState((prev) => ({ ...prev, open: false })),
      }));
    },
    [],
  );

  const openCexPriceDisplaySetupDialog: DialogStore['openCexPriceDisplaySetupDialog'] = useCallback(
    ({ cexPriceDisplaySetting, setCexPriceDisplaySetting }) => {
      setCexPriceDisplaySettingState((prev) => ({
        ...prev,
        open: true,
        cexPriceDisplaySetting,
        setCexPriceDisplaySetting,
        onClose: () => setCexPriceDisplaySettingState((prev) => ({ ...prev, open: false })),
      }));
    },
    [],
  );

  const openPriceConditionDialog: DialogStore['openPriceConditionDialog'] = useCallback(
    ({
      inputTokenAddress,
      outputTokenAddress,
      amountPerOrder,
      initialPriceCondition,
      onConfirm,
    }) => {
      setPriceConditionState((prev) => ({
        ...prev,
        open: true,
        inputTokenAddress,
        outputTokenAddress,
        amountPerOrder,
        initialPriceCondition,
        onConfirm,
        onClose: () => setPriceConditionState((prev) => ({ ...prev, open: false })),
      }));
    },
    [],
  );

  const openSlippageSetupDialog: DialogStore['openSlippageSetupDialog'] = useCallback(
    ({ slippage, onSlippageChange }) => {
      setSlippageSetupState((prev) => ({
        ...prev,
        open: true,
        slippage,
        onSlippageChange,
        onClose: () => setSlippageSetupState((prev) => ({ ...prev, open: false })),
      }));
    },
    [],
  );

  return (
    <DialogContext.Provider
      value={{
        openTokenSelector,
        openTokenSelectorV3,
        openStarredPairsDialog,
        openTokenDetailDialog,
        openTokenTransferDialog,
        openConfirmationDialog,
        openWalletConnectDialog,

        openChatProfileDialog: () => {},
        openChatNicknameEditDialog,
        openChatSendTokenDialog,
        openChatTokenDonationDialog,
        openChatSDoongELevelDialog,
        resetAllChatRelatedDialogs,

        setTokenTransferDialogNotClose,
        openNetworkSelectorDialog,

        openAddAddressBookV2Dialog,
        openAddressBookV2Dialog,
        openTransferTokenDialog,
        openCexPriceDisplaySetupDialog,

        openPriceConditionDialog,
        openSlippageSetupDialog,

        starredPairs,
        isStarredPair,
        toggleStarred,
        reloadStarredPairs,
      }}
    >
      {children}
      <TokenSelectorDialogV3 {...tokenSelectorV3State} />
      <TokenSelectorDialogV2 {...tokenSelectorState} />
      <TokenDetailDialog {...tokenDetailState} />
      <TokenTransferDialog {...tokenTransferState} />
      <StarredPairsDialog {...starredPairsState} />
      <ConfirmationDialog {...confirmationState} />
      {/* <ChatProfileDialog {...chatProfileState} /> */}
      <ChatNicknameEditDialog {...chatNicknameEditState} />
      <ChatSendTokenDialog {...chatSendTokenState} />
      <ChatTokenDonationDialog {...chatTokenDonationState} />
      <ChatSDoongELevelDialog {...chatSDoongELevelState} />
      <WalletConnectDialog {...walletConnectState} />
      <NetworkSelector {...networkSelectorState} />
      <AddAddressBookV2Dialog {...addAddressBookV2State} />
      <AddressBookV2Dialog {...addressBookV2State} />
      <TransferTokenDialog {...transferTokenState} />
      <CexPriceDisplaySetupDialog {...cexPriceDisplaySettingState} />
      <PriceConditionDialog {...priceConditionState} />
      <SlippageSetupDialog {...slippageSetupState} />
    </DialogContext.Provider>
  );
}

export const useDialog = () => {
  const context = useContext(DialogContext);

  if (context === undefined) {
    throw new Error(`useDialog must be used within a DialogContextProvider`);
  }

  return context;
};
