import { memo } from 'react';

interface SCNRIconProps {
  className?: string;
}
const SCNRIcon = ({ className }: SCNRIconProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1471_2414)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00135 10.0892C-0.882258 6.5583 4.22355 -2.28902 10.225 1.04079L10.2454 1.05229L14.8893 3.73305C15.5468 4.11264 15.7722 4.95341 15.3926 5.61097C15.013 6.26852 14.1722 6.49386 13.5147 6.11428L8.88175 3.43986C6.03977 1.87509 3.63069 6.04772 6.40704 7.72617L8.89671 9.1633C9.55428 9.54288 9.77963 10.3836 9.40006 11.0412C9.02049 11.6988 8.17972 11.9241 7.52216 11.5446L5.02149 10.1011L5.00135 10.0892Z"
        className="fill-teal-400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50191 4.92379C6.88148 4.26622 7.72225 4.04087 8.37981 4.42044L10.8805 5.86393L10.9006 5.87578C16.7842 9.40669 11.6784 18.254 5.67693 14.9242L5.65661 14.9127L1.0127 12.2319C0.355144 11.8524 0.129802 11.0116 0.509387 10.354C0.888971 9.69647 1.72974 9.47113 2.3873 9.85071L7.02022 12.5251C9.8622 14.0899 12.2713 9.91727 9.49492 8.23882L7.00526 6.80169C6.34769 6.42212 6.12234 5.58135 6.50191 4.92379Z"
        className="fill-white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1471_2414">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default memo(SCNRIcon);
