import { Dialog } from '@headlessui/react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'next-i18next';
import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';

import { useKlipContext } from '@/context/klip';
import { DIALOG_BACKGROUND_COLOR } from '@/defines/consts';

const iOS =
  typeof navigator !== 'undefined' && !!navigator?.userAgent?.match(/(iPad|iPhone|iPod)/i);
const android =
  typeof navigator !== 'undefined' && navigator?.userAgent?.toLowerCase()?.indexOf('android') > -1;

/**
 * KlipQrPopup component is isolated from WalletConnectorV2 component.
 * Because there is some problem with zIndex.
 * WalletConnectorV2 component is included in MainHeaderV2, and MainHeaderV2's zIndex is 50.
 * But StakeDialog, UnstakeDialog, ClaiimDialog components have 1100 of zIndex In Stake page.
 * Even if the WalletConnectorV2 component's zIndex was set to higher than 1100,
 * the zIndex comparison was not effective because the parent components were compared.
 * Also, KlipQrPopup component is used not only wallet connect but also all transactions.
 */
export default function KlipQrPopup() {
  const { t } = useTranslation('common');

  const { requests } = useKlipContext();

  const [klipQRCodes, setKlipQRCodes] = useState<Record<string, string>>({});

  const requestKeys = requests.map((r: any) => r.requestKey).join('-');
  useEffect(() => {
    let destroyed;

    (async () => {
      const reqs = [...requests];
      const keepers = new Set();
      for (let i = 0; i < reqs.length; i += 1) {
        const requestKey = reqs[i].requestKey;
        const url = await QRCode.toDataURL(
          'https://klipwallet.com/?target=/a2a?request_key=' + requestKey,
        );
        if (destroyed) return;
        setKlipQRCodes((prev) => {
          return {
            ...prev,
            [requestKey]: url,
          };
        });
        keepers.add(requestKey);
      }
      setKlipQRCodes((prev) => {
        const updated: Record<string, string> = {};
        Object.keys(prev).forEach((k) => {
          if (!keepers.has(k)) return;
          updated[k] = prev[k];
        });
        return updated;
      });
    })();

    return () => {
      destroyed = true;
    };
  }, [requestKeys, requests]);

  useEffect(() => {
    const handleQrPopup = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;
      requests.forEach((request) => {
        request.cancel();
      });
    };

    document.addEventListener('keyup', handleQrPopup);

    return () => {
      document.removeEventListener('keyup', handleQrPopup);
    };
  }, [requests]);

  return (
    <Dialog
      as="div"
      open={requests.length > 0}
      className="fixed inset-0 z-[3000]"
      onClose={() => {
        requests.forEach((request) => {
          request.cancel();
        });
      }}
    >
      {requests.map(({ spec, requestKey, cancel, expiresAt }) => {
        if (!expiresAt) return;
        return (
          <div
            key={requestKey}
            className={clsx('fixed left-0 top-0 w-screen h-screen', DIALOG_BACKGROUND_COLOR)}
            onClick={() => {
              cancel();
            }}
          >
            <div
              className="max-w-md w-full h-fit absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-dark-card-color"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <h2 className="text-lg font-bold px-6 pt-6 text-white">Klip</h2>

              <p className="text-base px-6 text-gray-400">
                <Trans t={t} i18nKey={'klip-' + spec.name} values={spec} />
              </p>
              <div className="py-4 flex flex-col items-center space-y-3">
                {iOS || android ? (
                  <>
                    <p className="text-center">{t('Please proceed authorization on Klip app.')}</p>
                    <a
                      className="bg-teal-500 p-2 rounded-md font-semibold"
                      href={
                        iOS
                          ? 'kakaotalk://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=' +
                            requestKey
                          : 'intent://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=' +
                            requestKey +
                            '#Intent;scheme=kakaotalk;package=com.kakao.talk;end'
                      }
                    >
                      {t('Open Klip')}
                    </a>
                  </>
                ) : (
                  <>
                    <p className="text-center whitespace-nowrap">{t('scan-the-qr-code')}</p>
                    <img alt="QR Code" src={klipQRCodes[requestKey]} />
                  </>
                )}

                <p className="text-center whitespace-nowrap text-base text-white">
                  <Trans
                    t={t}
                    i18nKey={'klip-expires'}
                    components={{
                      expiresAt: <Moment duration={new Date()} date={expiresAt} />,
                    }}
                  />
                </p>
              </div>

              <div className="text-right px-5 pt-5">
                <button
                  onClick={() => {
                    cancel();
                  }}
                >
                  <p className="text-teal-500 text-sm font-bold p-6">{t('Cancel')}</p>
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </Dialog>
  );
}
