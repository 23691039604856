import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { matchSorter } from 'match-sorter';
import { Trans, useTranslation } from 'next-i18next';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import TokenIconV2 from '@/components/TokenIconV2';
import { StarIcon } from '@/components/vector';
import { useWalletContext } from '@/context/wallet';
import { TINY_AMOUNT_THRESHOLD } from '@/defines/consts';
import { LOCAL_STORAGE_KEYS } from '@/defines/local-storage-keys';
import {
  GCKAIA_ADDRESS,
  ImaginaryFiatAddress,
  IMAGINARY_FIAT_ADDRESS_MAP,
  SCNR_ADDRESS,
  ZERO_ADDRESS,
} from '@/defines/token-address';
import useBalancesV1 from '@/hooks/use-balances-v1';
import { useIsoMorphicLayoutEffect } from '@/hooks/use-isomorphic-layout-effect';
import useLocalStorageV2 from '@/hooks/use-local-storage-v2';
import usePrices from '@/hooks/use-prices';
import { useTokenStatsV2 } from '@/hooks/use-token-stats-v2';
import useTokens from '@/hooks/use-tokens';

import FilterMenuButton from './FilterMenuButton';
import SortMenuButton from './SortMenuButton';
import TokenBalance from './TokenBalance';

import type { FilterOption } from './FilterMenuButton';
import type { SortOption } from './SortMenuButton';
import type { Token } from '@/lib/tokens';
import type { MaybePromise } from '@/types';

const TOP_SORT_PRIORITY = [
  ...Object.values(IMAGINARY_FIAT_ADDRESS_MAP),
  ZERO_ADDRESS,
  GCKAIA_ADDRESS,
  SCNR_ADDRESS,
];

export interface TokenSelectorProps {
  label: string;
  open: boolean;
  onClose: (open: false) => void;
  onSelectToken: (token: Token) => MaybePromise<void>;
  withImaginaryFiats?: boolean;
}
export default function TokenSelectorDialogV2({
  label,
  open,
  onClose,
  onSelectToken,
  withImaginaryFiats = false,
}: TokenSelectorProps) {
  const { t } = useTranslation('tokens');
  const { t: ts } = useTranslation('swap');

  const { wallet } = useWalletContext();

  const { tokens, tokenAddressToToken } = useTokens({ withImaginaryFiats, whitelistedOnly: true });
  const { tokenStats } = useTokenStatsV2({ revalidateOnFocus: false, excludeImaginaryFiats: true });

  const [query, setQuery] = useState('');
  const [filterOption, setFilterOption] = useState<FilterOption>('f-none');
  const [sortOption, setSortOption] = useState<SortOption>('balances-dsc');
  const [currentIdx, setCurrentIdx] = useState(0);
  const ignoreMouseEnterRef = useRef(false);

  const [favoriteTokenAddresses, setFavoriteTokenAddresses, reloadFavoriteTokensFromLocalStorage] =
    useLocalStorageV2<string[]>(LOCAL_STORAGE_KEYS.favoriteTokens, []);

  useEffect(() => {
    if (open) {
      reloadFavoriteTokensFromLocalStorage([]);
    }
  }, [open, reloadFavoriteTokensFromLocalStorage]);

  const favoriteTokenAddressSet = useMemo(
    () => new Set(favoriteTokenAddresses),
    [favoriteTokenAddresses],
  );

  const toggleFavorite = useCallback(
    (address: string) => {
      if (!favoriteTokenAddresses) return;

      const isFavorited = favoriteTokenAddresses.includes(address);
      if (isFavorited) {
        // TODO: Type state from `useLocalStorage`
        setFavoriteTokenAddresses(favoriteTokenAddresses.filter((v: string) => v !== address));
      } else {
        setFavoriteTokenAddresses([...favoriteTokenAddresses, address]);
      }
    },
    [favoriteTokenAddresses, setFavoriteTokenAddresses],
  );

  // FIXME: This is a hack.
  // https://levelup.gitconnected.com/javascript-events-handlers-keyboard-and-load-events-1b3e46a6b0c3
  const debounceFlag = useRef(false);
  const debouncedKeyDown = (fn: Function) => (e: any) => {
    if (debounceFlag.current) return;
    debounceFlag.current = true;
    fn(e);
    setTimeout(() => (debounceFlag.current = false), 50);
  };

  const searchInputRef = useRef<HTMLInputElement>(null);

  const { balances } = useBalancesV1({ account: wallet?.account ?? null, skip: !open });
  const { prices } = usePrices();

  const calcBalanceAmountInUSDC = useCallback(
    (address: string) => {
      if (!balances || !prices || !tokenAddressToToken[address]) return 0;

      const balance = balances[address];
      const price = prices[address];
      const { decimals } = tokenAddressToToken[address];

      const amountInUSDC = (Number(balance ?? 0) * price) / 10 ** +(decimals ?? 18);

      return amountInUSDC;
    },
    [tokenAddressToToken, balances, prices],
  );

  const filteredTokens = useMemo(() => {
    // exclude KRW from the list
    let ts = tokens.filter((token) => token.address !== 'krw');

    // Show search result while searching with {symbol or address or name}
    if (query) {
      ts = matchSorter(ts, query, {
        keys: [
          { key: (item) => item.symbol },
          { key: (item) => t(item.address, '') },
          'name',
          { key: 'address', threshold: matchSorter.rankings.CONTAINS },
        ],
      });
    }

    if (!!balances && !!prices) {
      switch (sortOption) {
        case 'balances-dsc': {
          ts.sort((a, b) => {
            return calcBalanceAmountInUSDC(b.address) - calcBalanceAmountInUSDC(a.address);
          });
          break;
        }
        case 'volume-dsc': {
          ts.sort((a, b) => {
            const volumeA = tokenStats.find((t) => t.address === a.address)?.volume ?? 0;
            const volumeB = tokenStats.find((t) => t.address === b.address)?.volume ?? 0;

            return volumeB - volumeA;
          });

          break;
        }
        case 'tvl-dsc': {
          ts.sort((a, b) => {
            const tvlA = tokenStats.find((t) => t.address === a.address)?.tvl ?? 0;
            const tvlB = tokenStats.find((t) => t.address === b.address)?.tvl ?? 0;

            return tvlB - tvlA;
          });

          break;
        }
      }
    }

    ts.sort(
      (a, b) =>
        Number(favoriteTokenAddressSet.has(b.address)) -
        Number(favoriteTokenAddressSet.has(a.address)),
    ).sort((a, b) => {
      if (TOP_SORT_PRIORITY.includes(a.address) && TOP_SORT_PRIORITY.includes(b.address)) {
        return TOP_SORT_PRIORITY.indexOf(a.address) - TOP_SORT_PRIORITY.indexOf(b.address);
      }

      if (TOP_SORT_PRIORITY.includes(a.address)) {
        return -1;
      }

      return 0;
    });

    if (!!balances && !!prices) {
      switch (filterOption) {
        case 'f-hideSmall': {
          ts = ts.filter(({ address }) => {
            if (
              Object.values(IMAGINARY_FIAT_ADDRESS_MAP).includes(address as ImaginaryFiatAddress)
            ) {
              return true;
            }
            // Hide tokens without balances info (?!)
            if (!balances[address] || !prices[address]) return false;

            const amountInUSDC = calcBalanceAmountInUSDC(address);

            return amountInUSDC >= TINY_AMOUNT_THRESHOLD;
          });
          break;
        }
        case 'f-hideZero': {
          ts = ts.filter(({ address }) => {
            if (
              Object.values(IMAGINARY_FIAT_ADDRESS_MAP).includes(address as ImaginaryFiatAddress)
            ) {
              return true;
            }
            if (!balances[address]) return false;

            const balance = balances[address];

            return Number(balance) > 0;
          });
          break;
        }
        case 'f-none': {
          break;
        }
      }
    }

    return ts;
  }, [
    balances,
    prices,
    favoriteTokenAddressSet,
    filterOption,
    sortOption,
    calcBalanceAmountInUSDC,
    query,
    t,
    tokens,
    tokenStats,
  ]);

  useIsoMorphicLayoutEffect(() => {
    setCurrentIdx(0);
  }, [filteredTokens]);

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => {
        setQuery('');
        setCurrentIdx(0);
      }}
    >
      <Dialog
        as="div"
        className="fixed inset-0 z-[1500] overflow-y-auto p-4 sm:p-6 md:p-20"
        onClose={onClose}
        initialFocus={searchInputRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 transition-all bg-slate-900/60 backdrop-blur" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={clsx(
              'mx-auto max-w-2xl max-h-full transform divide-y divide-opacity-10 overflow-hidden rounded-xl shadow-2xl ring-1 transition-all',
              'divide-slate-600 bg-gray-900/80 ring-slate-50/5',
            )}
          >
            <div className="px-4 py-3 flex justify-between items-center">
              <label className="font-medium text-lg text-slate-200">{label}</label>
              <button
                className={clsx(
                  'rounded border px-1.5 py-px text-xs font-medium tracking-tight hover:opacity-70 outline-none',
                  'border-slate-600 bg-slate-900 text-slate-200',
                )}
                onClick={() => onClose(false)}
              >
                ESC
              </button>
            </div>
            <div className="flex items-center px-4 py-1">
              <SearchIcon
                className="pointer-events-none h-5 w-5 text-slate-50/40"
                aria-hidden="true"
              />
              <input
                ref={searchInputRef}
                className={clsx(
                  'h-12 outline-none w-full border-0 bg-transparent px-2 focus:ring-0 sm:text-sm',
                  'text-slate-50 placeholder-slate-500',
                )}
                placeholder="Search..."
                onChange={(event) => setQuery(event.target.value)}
                onKeyDown={debouncedKeyDown((e: KeyboardEvent) => {
                  if (currentIdx === -1) return;
                  if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    if (currentIdx === 0) return;

                    ignoreMouseEnterRef.current = true;
                    const elem = document.getElementById(`token-${currentIdx - 1}`);
                    elem?.scrollIntoView({ block: 'nearest' });
                    setCurrentIdx(currentIdx - 1);
                  } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    if (!filteredTokens.length || currentIdx >= filteredTokens.length - 1) return;

                    ignoreMouseEnterRef.current = true;
                    const elem = document.getElementById(`token-${currentIdx + 1}`);
                    elem?.scrollIntoView({ block: 'nearest' });
                    setCurrentIdx(currentIdx + 1);
                  } else if (e.key === 'Enter') {
                    e.preventDefault();
                    if (currentIdx === -1 || !filteredTokens.length) {
                      onClose(false);
                      return;
                    }

                    const token = filteredTokens[currentIdx];

                    if (!token) return;

                    onSelectToken(token);
                    onClose(false);
                    return;
                  }
                })}
                onBlur={(e) => e.currentTarget.focus()}
              />
              {wallet?.account ? (
                <>
                  <SortMenuButton sortOption={sortOption} onSortChange={setSortOption} />
                  <FilterMenuButton filterOption={filterOption} onFilterChange={setFilterOption} />
                </>
              ) : (
                <></>
              )}
            </div>

            {filteredTokens.length > 0 ? (
              <ul
                className="scroll-py-2 divide-y overflow-y-auto divide-slate-400/10"
                style={{ maxHeight: 'min(640px, 80vh)', minHeight: '120px' }}
              >
                <li className="p-2">
                  {/* TODO: [SCNR-17] We can list Favorite tokens here */}
                  <ul className="text-sm text-slate-200">
                    {filteredTokens.map((token, idx) => {
                      const isFavorited = favoriteTokenAddressSet.has(token.address);

                      return (
                        <li
                          className="flex"
                          key={token.address}
                          id={`token-${idx}`}
                          onMouseEnter={() => {
                            if (ignoreMouseEnterRef.current) {
                              ignoreMouseEnterRef.current = false;
                              return;
                            }

                            setCurrentIdx(idx);
                          }}
                        >
                          <button
                            className={clsx(
                              'mr-1 w-6 h-6 mt-auto mb-auto cursor-pointer select-none flex items-center justify-center rounded-full hover:bg-gray-50/5',
                              {
                                'opacity-0 pointer-events-none': TOP_SORT_PRIORITY.includes(
                                  token.address,
                                ),
                              },
                            )}
                            onClick={() => toggleFavorite(token.address)}
                          >
                            <StarIcon
                              className={clsx(
                                'h-[22px] w-auto',
                                isFavorited ? 'text-yellow-500' : 'text-gray-400',
                              )}
                            />
                          </button>
                          <button
                            className={clsx(
                              'min-w-0 text-left cursor-pointer select-none flex flex-1 items-center rounded-md px-3 py-2',
                              {
                                'bg-slate-50/5 text-slate-50': idx === currentIdx,
                              },
                            )}
                            onClick={() => {
                              onSelectToken(token);
                              onClose(false);
                            }}
                          >
                            <TokenIconV2
                              className="h-6 w-6"
                              width={24}
                              height={24}
                              address={token.address}
                            />
                            <div className="ml-3 min-w-0 flex flex-col flex-auto">
                              <div className="truncate">{`${t(token.address, token.name)} (${
                                token.symbol
                              })`}</div>
                              <span className="w-full text-xs text-ellipsis overflow-hidden text-slate-400">
                                {Object.values(IMAGINARY_FIAT_ADDRESS_MAP).includes(
                                  token.address as ImaginaryFiatAddress,
                                )
                                  ? t(`${token.address}_description`)
                                  : token.address}
                              </span>
                            </div>

                            <TokenBalance className="ml-3 flex-none" token={token} />
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            ) : (
              <div className="py-14 px-6 text-center sm:px-14">
                <ExclamationCircleIcon
                  className="mx-auto h-6 w-6 text-slate-50/40"
                  aria-hidden="true"
                />
                <p className="mt-4 text-sm leading-6 text-slate-50">
                  <Trans
                    t={ts}
                    i18nKey={
                      "We couldn't find any tokens with that term.<br />Check your search keyword or filter settings."
                    }
                  />
                </p>
              </div>
            )}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
