import { Trans, useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { LinkIcon } from '@/components/vector';
import {
  defaultSwapConfig as dsc,
  QuoteResult,
  SwapMode,
  SWAP_DEFAULT_REFRESH_INTERVAL,
  SWAP_INITIAL_REFRESH_INTERVAL_ON_ERROR,
  SWAP_INPUT_DEBOUNCE_MS,
  SWAP_MAX_REFRESH_INTERVAL,
  SWAP_REFRESH_DISABLED_TIME,
  SWAP_SLIPPAGE_DENOMINATOR,
} from '@/config/swap';
import { useWalletContext } from '@/context/wallet';
import { ZERO_ADDRESS } from '@/defines/token-address';
import useBalancesV1 from '@/hooks/use-balances-v1';
import useBrowserDocumentVisibility from '@/hooks/use-browser-document-visibility';
import useDebounce from '@/hooks/use-debounce';
import useWSCallSWR, { WithFetchedAt } from '@/hooks/use-ws-call-swr';
import bigIntToHexString from '@/utils/bigint-to-hex-string';
import { floorToInt } from '@/utils/floor-to-int';
import setIntervalWithTimeout from '@/utils/setIntervalWithTimeout';

type UseQuoteOptions = {
  tokenInAddress: string | null;
  tokenOutAddress: string | null;
  amountIn: string;
  payWithSCNR?: boolean;
  minAmountOutOverride?: string;
  isPaused?: boolean;

  slippage?: number;
  mode?: SwapMode;
  autoRefresh?: boolean;

  onQuoteRefresh?: (quote: WithFetchedAt<QuoteResult>) => void;
  useRefreshDisabled?: boolean;
};

export function useQuote({
  tokenInAddress,
  tokenOutAddress,
  amountIn,
  payWithSCNR = false,
  minAmountOutOverride,
  isPaused,

  slippage = dsc.slippage,
  mode = dsc.mode,
  autoRefresh = dsc.autoRefresh,

  onQuoteRefresh,
  useRefreshDisabled = false,
}: UseQuoteOptions) {
  const { enqueueSnackbar } = useSnackbar();
  const { t: tc } = useTranslation('common');

  const { wallet, updateInvalidator } = useWalletContext();
  const {
    fetchedAt: balancesFetchedAt,
    updateBalancesOf,
    mutate: mutateBalances,
  } = useBalancesV1({ account: wallet?.account ?? null });

  const [transactionInFlight, setTransactionInFlight] = useState(false);
  const transactionInFlightRef = useRef(false);
  useEffect(() => {
    transactionInFlightRef.current = transactionInFlight;
  }, [transactionInFlight]);

  const [inDebouncing, setInDebouncing] = useState(false);
  const [quoteRefreshTimer, setQuoteRefreshTimer] = useState({ updatedAt: 0, nextRefresh: 0 });

  // use `errorRefreshInterval.current` if it is not null (which grows twice on consecutive retry)
  // use `DEFAULT_REFRESH_INTERVAL` if `errorRefreshInterval.current` is null
  const errorRefreshInterval = useRef<number | null>(null);

  const { visible } = useBrowserDocumentVisibility();

  const wsCallMethod = useMemo(
    () => (tokenInAddress && tokenOutAddress && Number(amountIn) > 0 ? 'quote' : null),
    [tokenInAddress, tokenOutAddress, amountIn],
  );
  const wsCallParams = useMemo(() => {
    setInDebouncing(true);
    return {
      from: wallet?.account || ZERO_ADDRESS,
      tokenInAddress,
      tokenOutAddress,
      amount: amountIn,
      slippageNumerator: floorToInt(slippage).toString(),
      slippageDenominator: SWAP_SLIPPAGE_DENOMINATOR,
      mode,
      minAmountOutOverride,
      payWithSCNR,
    };
  }, [
    tokenInAddress,
    tokenOutAddress,
    amountIn,
    payWithSCNR,
    slippage,
    mode,
    minAmountOutOverride,
    wallet?.account,
  ]);
  const debouncedWsCallParams = useDebounce(wsCallParams, SWAP_INPUT_DEBOUNCE_MS);

  useEffect(() => {
    setInDebouncing(false);
  }, [debouncedWsCallParams]);

  const updateBalancesUsingQuoteResult = useCallback(
    (result: any) => {
      if (
        !result?.tokenInAddress ||
        !result?.tokenInBalance ||
        !result?.tokenOutAddress ||
        !result?.tokenOutBalance ||
        !result.fetchedAt ||
        !balancesFetchedAt
      ) {
        return;
      }

      if (result.fetchedAt <= balancesFetchedAt) {
        return;
      }

      updateBalancesOf(
        {
          [result.tokenInAddress]: String(BigInt(result.tokenInBalance)),
          [result.tokenOutAddress]: String(BigInt(result.tokenOutBalance)),
        },
        result.fetchedAt,
      );
    },
    [updateBalancesOf, balancesFetchedAt],
  );

  const {
    result: quoteResult,
    isValidating: quoteLoading,
    mutate: invalidateQuote,
  } = useWSCallSWR<QuoteResult>(wsCallMethod, debouncedWsCallParams, {
    onFetchSuccess: (result) => {
      if (transactionInFlightRef.current) {
        console.error('Quote fetch success while transaction in flight');
        throw new Error('transaction in flight');
      }
      onQuoteRefresh && onQuoteRefresh(result);
      setQuoteRefreshTimer({
        updatedAt: Date.now(),
        nextRefresh: Date.now() + SWAP_DEFAULT_REFRESH_INTERVAL,
      });
      updateBalancesUsingQuoteResult(result);
      errorRefreshInterval.current = null;
    },
    onFetchError: (err) => {
      if (!transactionInFlightRef.current) {
        const interval = errorRefreshInterval.current
          ? Math.min(errorRefreshInterval.current * 2, SWAP_MAX_REFRESH_INTERVAL)
          : SWAP_INITIAL_REFRESH_INTERVAL_ON_ERROR;
        setQuoteRefreshTimer({
          updatedAt: Date.now(),
          nextRefresh: Date.now() + interval,
        });
        errorRefreshInterval.current = interval;
      }

      return null;
    },
    revalidateOnMount: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
    isPaused: () => transactionInFlightRef.current || !visible || !!isPaused,
    withTimstamp: true,
  });

  useEffect(() => {
    let destroyed: boolean;

    setIntervalWithTimeout((clear: Function) => {
      if (destroyed) {
        clear();
        return;
      }

      if (inDebouncing || quoteLoading || !autoRefresh) return;

      // refresh disabled
      if (
        useRefreshDisabled &&
        (lastActiveTimeRef.current ?? Date.now()) + SWAP_REFRESH_DISABLED_TIME < Date.now()
      ) {
        setRefreshDisabled(true);
        return;
      }

      if (quoteRefreshTimer.nextRefresh < Date.now()) {
        invalidateQuote();
        return;
      }
    }, 100);

    return () => {
      destroyed = true;
    };
  }, [
    inDebouncing,
    quoteLoading,
    autoRefresh,
    quoteRefreshTimer.nextRefresh,
    invalidateQuote,
    useRefreshDisabled,
  ]);

  const lastActiveTimeRef = useRef<number>(Date.now());
  const [refreshDisabled, setRefreshDisabled] = useState<boolean>(false);

  const lastActivatedAt = useCallback(() => {
    lastActiveTimeRef.current = Date.now();
    setRefreshDisabled(false);
  }, []);

  useEffect(() => {
    // reset refreshDisabled when quoteResult is updated
    setRefreshDisabled(false);
  }, [quoteResult]);

  /**
   * Execute swap
   * @returns txId if success, false if failed, null if cancelled
   */
  const executeSwap = async () => {
    if (
      !wallet ||
      inDebouncing ||
      !quoteResult ||
      !quoteResult.quote ||
      !!quoteResult.quote.txError
    )
      return null;

    // update lastActiveTimeRef when executing swap
    if (useRefreshDisabled) {
      lastActivatedAt();
    }

    let success: string | false = false;
    setTransactionInFlight(true);
    try {
      /**
       * The default gas limit of nodes used in Neopin is insufficient to execute swap transaction.
       * Therefore, when executing a swap transaction through the Neopin wallet provider, an estimateGas is conducted, and the corresponding gas limit is utilized.
       */
      if (wallet && wallet.type === 'walletConnect' && wallet?.metadata?.name === 'NEOPIN Wallet') {
        const estimatedGas = await wallet.estimateGas(quoteResult.quote.tx);
        quoteResult.quote.tx.gas = bigIntToHexString(estimatedGas);
      }

      const res = await wallet.send(
        wallet.type === 'klip'
          ? quoteResult.quote.txKlip
          : wallet.type === 'metamask' || wallet.type === 'walletConnect'
          ? quoteResult.quote.tx
          : quoteResult.quote.txKlay,
        {
          name: quoteResult.quote.txType || 'swap',
          quote: quoteResult.quote,
        },
      );
      enqueueSnackbar(
        <Trans
          t={tc}
          i18nKey="transaction-submitted"
          components={{
            container: <div className="flex items-center" />,
            scopeLink: (
              <a
                className="pl-1 text-white"
                href={'https://kaiascope.com/tx/' + res}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
            linkIcon: <LinkIcon />,
          }}
        />,
        { variant: 'success' },
      );
      success = res;
    } catch (err) {
      enqueueSnackbar(
        <Trans
          t={tc}
          i18nKey="transaction-error"
          values={{
            message: err.message,
          }}
        />,
        { variant: 'error' },
      );
    } finally {
      lastActivatedAt();
      setTransactionInFlight(false);
      updateInvalidator();
      setTimeout(() => {
        mutateBalances();
        invalidateQuote();
      }, 1000 + SWAP_INPUT_DEBOUNCE_MS);
    }

    return success;
  };

  return {
    inDebouncing,
    quoteRefreshTimer,
    transactionInFlight,
    quoteLoading,
    quoteResult,

    refreshDisabled,
    lastActivatedAt,

    errorRefreshInterval,

    invalidateQuote,
    executeSwap,
  };
}
